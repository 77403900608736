import api from '@/lib/api'
import store from '@/store'
import SamlAuth from './samlAuth'
import GoogleAuth from './googleAuth'
import PasswordAuth from './passwordAuth'
import KeyAuth from './keyAuth'

const methods = {
  saml: new SamlAuth(),
  google: new GoogleAuth(),
  password: new PasswordAuth(),
  key: new KeyAuth()
}

const tokenUrl = 'auth/token'

async function authorizeRequest (request) {
  if (request.url !== tokenUrl) {
    const key = store.getters['auth/key']
    if (key) {
      const credentials = Buffer.from(`${key}:`, 'utf8').toString('base64')
      request.headers.Authorization = `Basic ${credentials}`
      return request
    }
    const token = await getAccessToken()
    if (token) {
      request.headers.Authorization = `Bearer ${token}`
    }
  }
  request.headers['Strongline-Slug'] = store.getters.slug
  request.headers['Strongline-Domain'] = store.getters.domain
  return request
}

api.interceptors.request.use(authorizeRequest)

api.interceptors.response.use((response) => {
  store.dispatch('auth/valid', true)
  return response
}, async function (error) {
  const request = error.config
  if (error.response && error.response.status === 401) {
    if (store.getters['auth/user']) {
      if (request.url !== tokenUrl && !request._retry) {
        request._retry = true
        store.commit('auth/SET_ACCESS_TOKEN')
        return api.request(await authorizeRequest(request))
      } else {
        store.dispatch('auth/valid', false)
        return Promise.reject(error)
      }
    }
  }
  return Promise.reject(error)
})

async function getAccessToken () {
  const accessToken = store.getters['auth/accessToken']
  if (accessToken && accessToken.expiresAt > Date.now()) {
    return accessToken.token
  }
  try {
    const refreshToken = store.getters['auth/refreshToken']
    if (!refreshToken || !refreshToken.token || refreshToken.expiresAt < Date.now()) return
    const result = await api.get(tokenUrl, {
      headers: { Authorization: `Bearer ${refreshToken.token}` },
      timeout: 60000
    })
    if (result) {
      const { data } = result
      store.commit('auth/SET_ACCESS_TOKEN', data.accessToken)
      return data.accessToken.token
    } else {
      throw new Error('Error refreshing access token')
    }
  } catch (error) {
    console.log(error)
  }
}

function setLoading (value) {
  store.commit('auth/SET_LOADING', value)
}

async function initialize () {
  try {
    setLoading(true)
    await store.dispatch('auth/login')
  } catch (error) {
    console.log(error)
  } finally {
    setLoading(false)
  }
}

async function login (method, options) {
  try {
    setLoading(true)
    const auth = methods[method]
    if (!auth) {
      throw new Error(`unknown authentication method: ${method}`)
    }
    const tokens = await auth.login(options)
    if (tokens) {
      store.commit('auth/SET_ACCESS_TOKEN', tokens.accessToken)
      store.commit('auth/SET_REFRESH_TOKEN', tokens.refreshToken)
    }
    await store.dispatch('auth/login', options.after)
  } finally {
    setLoading(false)
  }
}

async function logout (redirectParams) {
  try {
    setLoading(true)
    await store.dispatch('auth/logout', redirectParams)
  } catch (error) {
    console.log(error)
  } finally {
    setLoading(false)
  }
}

export default {
  getAccessToken,
  initialize,
  login,
  logout
}
