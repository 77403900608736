<template>
  <div>
    <l-control :position="position" class="leaflet-bar leaflet-control">
      <a href="#" @click="onToggleMode('plan')" title="Edit plan" :class="editPlan && 'on'">
        <i class="fa fa-map" />
      </a>
      <a href="#" v-if="editPlan" @click="onUploadTap" title="Upload plan">
        <i class="fa fa-upload" />
      </a>
      <a href="#" v-if="editPlan" @click="onToggle('show')" title="Toggle current plan" :class="!state.show && 'on'">
        <i class="fa fa-eye-slash" />
      </a>
      <a href="#" v-if="editPlan" @click.prevent="onRotate($event, -5)" title="Rotate left (shift+click for fine grain)">
        <i class="fa fa-undo" />
      </a>
      <a href="#" v-if="editPlan" @click.prevent="onRotate($event, 5)" title="Rotate right (shift+click for fine grain)">
        <i class="fa fa-redo" />
      </a>
    </l-control>
    <input ref="upload" type="file" @change="$emit('upload', $event)" accept="image/*" hidden />
  </div>
</template>

<script>
import {
  findRealParent,
  LControl
} from 'vue2-leaflet'

const L = window.L

export default {
  props: {
    position: {
      type: String,
      default: 'topleft'
    },
    mode: {
      type: String,
      default: ''
    },
    show: {
      type: Boolean,
      default: false
    }
  },
  components: {
    LControl
  },
  data () {
    return {
      state: {
        mode: '',
        show: this.show
      }
    }
  },
  watch: {
    mode () {
      this.setMode(this.mode)
    },
    show () {
      this.state.show = this.show
    }
  },
  async mounted () {
    this.map = findRealParent(this.$parent).mapObject
  },
  computed: {
    editPlan () {
      return this.state.mode.startsWith('plan')
    }
  },
  methods: {
    setMode (mode) {
      this.state.mode = this.mode
    },
    onToggle (name) {
      if (!(name in this)) return
      this.state[name] = !this.state[name]
      this.$emit(`toggle:${name}`, this.state[name])
    },
    onToggleMode (mode) {
      if (this.state.mode === mode) {
        this.state.mode = ''
      } else {
        this.state.mode = mode
      }
      this.$emit('change:mode', this.state.mode)
    },
    onUploadTap () {
      this.$refs.upload.click()
    },
    onRotate (event, degrees) {
      if (event.metaKey || event.ctrlKey || event.shiftKey) {
        degrees = degrees > 0 ? 0.2 : -0.2
      }
      const bearing = (this.map._bearing * L.DomUtil.RAD_TO_DEG) + degrees
      this.map.setBearing(bearing)
      this.$emit('rotate', bearing)
    }
  }
}
</script>

<style scoped>
a.on {
  background-color: #007bff;
}

a.on i.fa {
  color: white;
}
</style>
