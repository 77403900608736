import config from '../../../processor/src/config'

const domains = Object.keys(config.domains)

export function barcodeCompare (a, b) {
  const acode = (a.config.logistics || {}).barcode
  const bcode = (b.config.logistics || {}).barcode
  if (acode !== bcode) {
    return acode.localeCompare(bcode, 'en', { numeric: true })
  }
  return a.id.localeCompare(b.id, 'en')
}

export function detectDomain () {
  if (process.env.VUE_APP_DOMAIN) {
    return process.env.VUE_APP_DOMAIN
  }
  const { hostname } = document.location
  const query = new URLSearchParams(window.location.search)
  const queryDomain = query.get('authDomain')
  const parts = hostname.split('.').map(part => part.split('-')[0]).reverse()
  const domainIndex = parts.findIndex(part => domains.includes(part))
  const domain = parts[domainIndex]
  return queryDomain || domain
}

export function isDomain (text = '') {
  return domains.includes(text.split('-')[0])
}
