module.exports = {
  rootSlug: 'admin',
  logisticsRootSlug: 'logisticsAdmin',
  defaultDomain: 'strongline',
  logistics: {
    factorySlug: 'factory',
    warehouseSlug: 'smpwarehouse',
    inventorySlug: {
      canopyprotect: 'inventory',
      strongline: 'strongline-inventory'
    }
  },
  adminApiKey: 'Z50YgPEyPMEFmMVTIvWBbWo6UB43n14C',
  superAdminApiKey: 'IZoWO3jLHiV5eIeUxfAhkSGD4ayWf6qj',
  email: {
    maintainer: 'dev@smplabs.com'
  },
  gateways: {
    disconnectTimeout: {
      GW2: 30000, // ms
      NODE1: 3600000,
      GWL1: 60000
    },
    defaultStandardFirmware: '2d25127'
  },
  consoles: {
    disconnectTimeout: 180000 // ms
  },
  tags: {
    atDistroStationTimeout: 3600 * 1000 // ms
  },
  apiEnvironments: {
    prod: { url: 'https://api.strongline.smplabs.com', apiKey: 'Z50YgPEyPMEFmMVTIvWBbWo6UB43n14C' },
    staging: { url: 'https://api.strongline-staging.smplabs.com', apiKey: 'Z50YgPEyPMEFmMVTIvWBbWo6UB43n14C' },
    dev: { url: 'http://localhost:8000', apiKey: 'Z50YgPEyPMEFmMVTIvWBbWo6UB43n14C' }
  },
  domains: {
    strongline: {
      branding: {
        domain: 'strongline',
        name: 'Strongline',
        company: 'Strongline',
      },
      host: {
        production: 'strongline.smplabs.com',
        staging: 'strongline-staging.smplabs.com',
        local: 'strongline-dev.smplabs.com'
      }
    },
    canopyprotect: {
      branding: {
        domain: 'canopyprotect',
        name: 'Canopy Protect',
        company: 'Canopy',
      },
      host: {
        production: 'canopyprotect.com',
        staging: 'canopyprotect-staging.com',
        local: 'canopyprotect-dev.com'
      }
    }
  },
  dashboardUrls: {
    production: {
      strongline: 'https://admin.strongline.smplabs.com',
      canopyprotect: 'https://admin.canopyprotect.com'
    },
    staging: {
      strongline: 'https://admin.strongline-staging.smplabs.com',
      canopyprotect: 'https://admin.canopyprotect-staging.com'
    },
    local: 'http://localhost:8080'
  },
  deviceQrUrls: {
    production: {
      strongline: 'https://strongline.smplabs.com',
      canopyprotect: 'https://canopyprotect.com'
    },
    staging: {
      strongline: 'https://strongline-staging.smplabs.com',
      canopyprotect: 'https://canopyprotect-staging.com'
    }
  },
  productName: {
    strongline: 'Strongline',
    canopyprotect: 'Canopy Protect'
  },
  google: {
    clientId: '901925604512-7j7cgpcfh67r8uvt7c1efhtsfipq041c.apps.googleusercontent.com',
    clientSecret: '0gb4VoiJ-f7ujmJBu7B2zVnE',
    credentials: {
      "type": "service_account",
      "project_id": "strongline",
      "private_key_id": "461721f08113c0e3af4baf5a20b45c7126d0248b",
      "private_key": "-----BEGIN PRIVATE KEY-----\nMIIEvAIBADANBgkqhkiG9w0BAQEFAASCBKYwggSiAgEAAoIBAQC/xlKiFOgfp8g/\n9mMKD3m9AtEnquSKdK9FOVmgsP/hvpqJ+SCTiSsBGRHxX90ckbOApz0Aebebiy8H\n+L0Ork9K1ayaCP5ZPff2VRJfU+9DETo2qvAhRCULV1sNq7zHKSr1t78FRPc2zx0p\nlUIvagsYWtomHC21BQJCb6JEyQXo9OdCnPESa90LBUcHCvz1VltuauurrYoVbfk8\n+TVPVJtXJPcjRRLcKI1Jf5IoWPa90Ls9vof/2tqRwuK7vfQps88GCKLvrIu64bf8\n6S3xqlSW6AIsFLEghSVJsWoEZt/4mgF+AYWjfvHtQzRZJ9dfeyvyUH+oH3f1QkTd\n3PF4VXQ/AgMBAAECggEALLv+95cH5KZ3RFBdnvGoNKptDqNDB3cbkXrEyQKw1cBn\n/SpTaobzNKDb+iGiCvGD3U1lofBCn7JyaJohw8Ruc6bvSckyR6/4YQpMLFeAdvUU\nTr9GH4RtXb1lXIWEljKotkPD9980CaF7jIEoPLYwW2CKBZzKHrFJ6yRuRjxcOBBN\nJLMoG4fcMEcQuKTLWzP+/31xC+B3IL9eiz+D++dsuoNfMzfDi4bJLSt7eh1rH1yu\ny74B9V2WwDQhARP6aRdJPUabHGlL3TXylBEHfBsGyf3uOlub6LbxZMUgoNQRDJ2U\ny7F1mp7ybOk0tTFkbuVR+7fr6FL1txWUZ0Tn+7nilQKBgQDuBwehqUdZmwHazJk3\nym32DLVVbAYG0IxEVFtMnCkbSU4W3S488z43Z+ruUCaWpUKxXRbWboJX5uQy9Xe1\nN+34I8UYsxLk6/kpcwUcdcsZ9sP57ljXv+8SBmJJOHbgSdUbJdREWx3vJMD/vWPd\n5zLHDf/b5JQgKyDsrY2dLQUBfQKBgQDOQT8j1/ddAyDnUo7XOldoh7TTCeI5H7bx\nDql+PYqOFGKfsUVM7vahvW6u9COEmMv2KSaH1u6GXyofX3B2RWXHzaTieln/HdnQ\n04dBHWJXXocDK7Wy8MLI0x7xrMmHDUvlk/JBQZq9rROy1twE60m+gYALcNboQy4W\nkrxoFd05awKBgHHqkV9wSYJ76e5SPv3xdqDsQhiow1Wdl5PYH33RuuahNatBnbBz\n5ZHUvR3i5CTy4LFL0PM9Hkp9aTduPSVQqCY60Eha468FtFB/CLIvOc2qSO6jgId5\nByzEO2O5j0MWctQ0TZuNVA0wtOFwPYxk6utTKuGNSsr+eRpskTro4d1tAoGAWu1w\nxICFUL/8VX6T8laKCk8pbJBC5yQQJp1sI3dGCfT6taQVBSGkIRG+JesDbYVfBZqG\nngQnC54Ql9wfXESj5j3GnwGJg2rJh8J5Mj+eBx5pLwCqDcUOWjKYxbqC7cLy3rcm\naZZefZg7wu/t900zKYU1ydVpKOPVAaaW4NwitxkCgYAH9KOunKunSX6u+3KYzfso\nMkYXpmNru8MMBP3y279zK3uII9YW7UItHy3Gjd1sGI33ephOcKNxvcCVomZu+Hxq\nZV3ZoHBvlrOQyUYQQMctCN7KwHV/XryyKEYO5AbwObtHELm8ubGK1TvApTSFoEEV\nn73jndXfnJse636qvhP5Sw==\n-----END PRIVATE KEY-----\n",
      "client_email": "processor@strongline.iam.gserviceaccount.com",
      "client_id": "117866640087517913043",
      "auth_uri": "https://accounts.google.com/o/oauth2/auth",
      "token_uri": "https://oauth2.googleapis.com/token",
      "auth_provider_x509_cert_url": "https://www.googleapis.com/oauth2/v1/certs",
      "client_x509_cert_url": "https://www.googleapis.com/robot/v1/metadata/x509/processor%40strongline.iam.gserviceaccount.com"
    },
    storage: {
      buckets: {
        default: 'strongline-images',
        images: 'strongline-images',
        sftp: '%s-sftp',
        backups: 'strongline-backups',
        exports: 'strongline-exports',
        orders: 'strongline-orders'
      },
      paths: {
        tiles: 'tiles'
      },
      signing: {
        domain: (bucketId) => `${bucketId}.storage.smplabs.com`,
        prefix: (domain) => `https://${domain}/`,
        key: {
          name: 'csk',
          value: '2mEoIhs-MtAfnk1CtEOO2Q==',
          expirationTime: 60 * 60 // 1 hour
        }
      },
      linkExpirationTime: 15 * 60 * 1000 // 15 minutes
    },
    saml: {
      cert: "MIIDdDCCAlygAwIBAgIGAWtMpI34MA0GCSqGSIb3DQEBCwUAMHsxFDASBgNVBAoTC0dvb2dsZSBJbmMuMRYwFAYDVQQHEw1Nb3VudGFpbiBWaWV3MQ8wDQYDVQQDEwZHb29nbGUxGDAWBgNVBAsTD0dvb2dsZSBGb3IgV29yazELMAkGA1UEBhMCVVMxEzARBgNVBAgTCkNhbGlmb3JuaWEwHhcNMTkwNjEyMTcwMzAwWhcNMjQwNjEwMTcwMzAwWjB7MRQwEgYDVQQKEwtHb29nbGUgSW5jLjEWMBQGA1UEBxMNTW91bnRhaW4gVmlldzEPMA0GA1UEAxMGR29vZ2xlMRgwFgYDVQQLEw9Hb29nbGUgRm9yIFdvcmsxCzAJBgNVBAYTAlVTMRMwEQYDVQQIEwpDYWxpZm9ybmlhMIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAwEgEJEYMBPEHimcb2Bp9l2ueH0ks0CoyR+OzrDD2GXz1u16ciSyzA9u5BhRyD8cP6vEmEyxVx7MGTIfoco/jRIV2mFcOjzoqZOuPl+CWVFiGkoDvGL+58tJfuCHTCsCHQNCH/GHLeIofXpO1v6Gs2SlqeNWMtBanIQrJjo34qAE5DkjPWffR+Kc39a+9rgVxN1Q2gMAA2Bh40I7hjxKJ20au+xsWp/aNomwEbI3HlX25VGUgCwmq1eB+tuiLOS4bB4yuacyXM0ew4cGPiu86c4nkAlk3MbmaTxb/Z1vwZj7tQgMN7ShHqkjriW35QRuDX+nmrIMXjhPST5US37BaqQIDAQABMA0GCSqGSIb3DQEBCwUAA4IBAQBRGPU46kB/herOQpgb5q9mJ/jnQ2rhufABEzre241H+ViGFI3RolmNWh2jGsBDE7NN3j64d5Ijs0Cs8JyLCa9yjPTaK19U8Ja2fS7fXPKPD07B6OqkBb4urgGz2cAG3lo2m/pE6nD61xNCKKCkKMyEN+V83nxRzRGSGdSrUSB0VmyBGY7quSvhm6puOjxEL4r2nPX+oxdn+d+Zsm1uvludrz58q3Y7rQBLqfMPreICSVyIV7d8aKIRI82xU+o3spi1vdF5iIafqwG3iqebly6WOBCaRZmyWTAMEdbSRcr0ssKcDid/GI0AgwTrZlbT3WkFsmray49TApLGvwdbTgO3"
    }
  },
  network: {
    defaultPopKey: 'Wr4YfDdwhB0gPLAajOtybw==',
    mqttAuth: 'smp:sgPGFweSK876',
    mqttDebugClientId: 'NIbFqyTToVEb'
  },
  bridge: {
    apiKey: 'Z50YgPEyPMEFmMVTIvWBbWo6UB43n14C',
    remotes: ['prod-tju', 'prod-ochsner', 'prod-camc', 'prod-geisinger', 'prod-vmmc', 'prod-eagleville', 'prod-baycare'],
    credentials: {
      "dev": {
        "type": "service_account",
        "name": "Development",
        "project_id": "staff-duress-1",
        "private_key_id": "edaff6d621b37b0c3e83a63342d0f6150b10b6e5",
        "private_key": "-----BEGIN PRIVATE KEY-----\nMIIEvAIBADANBgkqhkiG9w0BAQEFAASCBKYwggSiAgEAAoIBAQC6WYQD3BnvA9tV\n8yzeXafRBBT8HTXioyT5xtpPyMx23WDLyXn3clYB8cM+edgQ0YzDdj6II5ax3d2K\nub2ZEBHyEY/UM7vd8tnilyh0GrWowvCeSG8W7RO+kXlkV8XrtBePXGVM6SxVajfG\nPwgaSYEXH1p1CF7HFSxjhdRGYvSPPwFwITtqxHI6tScl1bZm8DJA1GShcrpRhhWU\nez24BzI4AxzANjgGyxUR5N4mbnmcwDcAdxQoBxm1RC8pSd58lWocVprnyW2WJWTb\nrAfWK7qCQS3zLWaWEjy6TkYsf+qKb00X/NJJ2Z5P8iMhmJFfajmAJQ1LFWUTRblo\nFC5taVXNAgMBAAECggEAAqrEJcrFrT9ed4RdxYCiJae0m0sESdVLa6ejkkzr449K\n8se3yAXvXupA3U5n2MbjAQ8MUlff1GdBI+Gr8PqDNQRcKXvPERxIGfvdkwx+QidF\nNWZJ1BJr9OSjSMaP0jGlkle5VG+pkPfyEejEbLnj3iyaEGmSVszslVplkwH8SDxt\n8YqCS92XDwK7tcQy8zEjj0juXPjEYhP8pwcTZ3MMz9ks3RK5grlUiW6bOAnfzRTP\n6fKQNj8okKtPyuJMtHK0CPNJHQwMS2vFimKc+TXxy+iFT8sgMGPe3nqRnvVf7EFB\nCCtiDabd7WS3S32otw9DqCLZWtqwR1YEEuM2aQ8euQKBgQDfB8u5BxhHKrxAb/5E\n6ODaIo1bEyOIT4MmphRCqEQYmOHOSuqKupC2JWk7CERWIMM/mvP2ggd+THXaizm2\nRLh7wHpSu+iWbBsiyK6DDwLOAz3B33gPLPv7ik01IsX0K+92NkRULjJ2k5a9Syoh\nrN4S7E/KCHzhAs/l/hRbEWfwiQKBgQDV5ZlHLssNeaMzrSuqlFzg3ne2+8Gdcp9l\nMlRlCNxEYq/3xfXDyHBmdsAxMtYY8XX5Uc6NaWXoh1MD0Jz+oKHaqI6by0cmFFyt\nlKSNT0Cqq15mMQUUV2YEBETgna2Psn2T4iFrJxWtcg0EButSLyUwkvt7nCpShVw/\niRiDO86CJQKBgDJkuIteJwYticulgnZfiC7Bj978PBMYpDGfZQSKsJg+MwdBti0S\napA9c8F65LXxQSyl/mm8h6Tp8dcnjiq47FZJ71myS6NxWdBKN+Q1CBU0dkfQDnif\nWXBEl51h9ghtxPWY3WeH6K8SOgUbbrGdNbBg5MZplI3i7May2jaZgDrBAoGAIT6g\nRioFsUB4ly2RHeAVV3X8UVpry1bnY0khSNrJsW9NPktsWVOr9KD8Wik2kYXl+iw2\n2E/LYQq3Yl5oKEM7JMsR7zD3C/oaNXhm4vewa1ZbQ7LWwUScPH+VXPvk3/K0Beod\nm5PlkKGrWDbjGFDvjM8rexjYkx7bKZzbTHpdLGkCgYB2sCaBLWDOvTCQuf0v3vwH\nzelwwHbuxuCDJj/F1qwo3SLo5sjjataMNTgzXUq8GWkIXvemy++lxDdSQhT8u5uV\npQ1rNVJHl2bht1L5vlfEnE/s4BbpLwBHBzM7DSICQgy9dcqt1EfOXQBNSk1LZakt\nvcThPkfqv+Zy96HrPu2VbA==\n-----END PRIVATE KEY-----\n",
        "client_email": "gitlab@staff-duress-1.iam.gserviceaccount.com",
        "client_id": "102510517244217045096",
        "auth_uri": "https://accounts.google.com/o/oauth2/auth",
        "token_uri": "https://oauth2.googleapis.com/token",
        "auth_provider_x509_cert_url": "https://www.googleapis.com/oauth2/v1/certs",
        "client_x509_cert_url": "https://www.googleapis.com/robot/v1/metadata/x509/gitlab%40staff-duress-1.iam.gserviceaccount.com"
      },
      "staging": {
        "type": "service_account",
        "name": "Staging",
        "project_id": "sd-test-01",
        "private_key_id": "654fb08bc12066c271cac3abbc9f097118676898",
        "private_key": "-----BEGIN PRIVATE KEY-----\nMIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQCv4kzBvjUkcooQ\nBHCDor5yTMmrCQJmZEiNRJrkPbiGwSaDH20Ac+OMhnjSfjNkDVCpCTE0h6JU1f0a\n1npg+7DDHvAxszluYoj0Z9IRP6tGxoSZ1pR/wqcIs+zA3dfsRSPKOYzZPnYifYdc\nHU3v9QAT9GSKIX3cebPQblX0F2qQen8mUXj/x+r0sRn1m40IQt5grJG7zBAvVP4z\nv9jWO1CgKUKBKamxTp+2RwafKb9LOH/k0witAHfF3BLAF/SjdPyTmKDYAFP8GpP5\ntipH39o6KVmE04ceLOTSJWyTarUMVE15ckSO5z+dxnND4uiqteFVGAdq5DqzonLZ\n1F0mM6JDAgMBAAECggEAQKT80DLQRnKgwu1Wy1MMC2eksavQiYCvV9jQ0zXKNiEU\nvhnVTtbJWjNsgfUnf00TieVag/OcGvFBo1UUSsQHPlgHMUUtxSALNUVZZMSRd6Rs\nRTykfOa2zNCQonyHKYxp8KBBX/GAqwGXOCh/JkRWwH7RWyGfKGNEz0VWay4WGT6H\nnAUaoDIaMjilWsLEfD+Hm38OcFBaLkVW9VUTU+SFDFTm8XAZVlF28ebhboJmgeTg\njexxifYAV9YjAiSADw2+svWb32JAPw+UU0u4ODNKJmv1dR2tk4sE7QkRrkIMZCIQ\nT0FpgQ1CljArUA3UcSm+hQyBRLSNf03oZ4hrQvoijQKBgQDu5JYkN9RJU8XBgcOE\nrlFQ4VVflXzzEiDyCUxIyMVRfGbw06NTu7p9KTpbDDiatAmNRx91E6vyQ/105DQj\nJJ51mTt6mEIIoTgWM1CrNOlEf9SKUmoCzJsi/ALazpvzcWadgeLYr4KmGJQFEGLE\nPif00aNcKuBRznZ6FD1JyMQFbwKBgQC8eqBFXLE6sZn87QWpNcVyyggpfUDBZi4A\nzCTrcp0fu1XAfzEfnp+FHZ94WT1Na35FDbhCMKp+KkZFurGKc9srQcJKspDpaP1l\nqAAJayX13aLV5EeTFs58RVXDBTiVCldnZW3nSis6jL13A8DMGc2tx3KVHtjxuSQs\niSaqlILObQKBgG7nbhCIXqRgIMmyDWvWZWfQBItoYeaSfvr+eAy+MGShBUU+a2+f\nEneyoSXNqaWgeWK+Jwt6Q3QoPk169/1srafYqHVEpDtPZTNhRU0GNlkXoztIAXEl\nPcxjBIxCKMI+3i23M/H2oQetF2K2dbO3S2Nrw5QxT4yK9hO8u6s6ak4/AoGAaWNT\n3JTw0HXgOam6wZu8dOv81l4f6EQfMvjzJ+flbYnNcB5utFR4s/EVazDS80hIyYnb\nXr9hhbxToMe8YENZ6H0Ti7r/BlRbZA0lQB72kdzFIT5MuzVEbf5C7xIrSkMHDlyL\nsIkrVMi/ZB3oHvfKpwHf0vv1qGuyi2iBA16ZKZ0CgYEAx2D8cbQwOeL1pKKKOilL\nkFm+jaQQBXtwSyOteLqPSD5wmBmiGy/hawdYmzWgpCGoHyj/dYnhdVMsBG76z47h\nZnAoEn2srjGP2DiMBJGRdEDDjcleynJdn8eCOPaR3QfmpAnFZKHL/mdddb9/6QOi\n55MXMNABBPYk8xRCteQIM0I=\n-----END PRIVATE KEY-----\n",
        "client_email": "gitlab@sd-test-01.iam.gserviceaccount.com",
        "client_id": "112443818568419514603",
        "auth_uri": "https://accounts.google.com/o/oauth2/auth",
        "token_uri": "https://oauth2.googleapis.com/token",
        "auth_provider_x509_cert_url": "https://www.googleapis.com/oauth2/v1/certs",
        "client_x509_cert_url": "https://www.googleapis.com/robot/v1/metadata/x509/gitlab%40sd-test-01.iam.gserviceaccount.com"
      },
      "prod-tju": {
        "type": "service_account",
        "name": "Thomas Jefferson University",
        "project_id": "sd-prod-cust-51f5764",
        "private_key_id": "ab85b064b7d3cd3e7329ef795d81f9025d37f75d",
        "private_key": "-----BEGIN PRIVATE KEY-----\nMIIEvAIBADANBgkqhkiG9w0BAQEFAASCBKYwggSiAgEAAoIBAQDL30yF69ywzhmU\niNQFLWSGxkBEBmpnp6McyYNtzH5yQn3YI+Q7wDEuU/o1aEpsj3AjeCZ0cCvKLe9a\nTTyFOsns1mZLY4K4ngqNxoOuh4vu7gpQCKAmS2xdUrq3bRmqFVzwO9cpoEVRaYJl\nx+cQTB7h9dutcZeA2AmzFaavtctf/BYb/who2uKgitXFyr63ixOAu7P8NmR8TKDL\niIaxZtNn9zBcszTzgDJg9tke45ZR9GU99eoDP77tssTM+DBuIrGdSYKN2U0CbLUL\ncr+5fqZRlqfRTq5Izkc39GeMkf6sFQnH4macusjAqazsV7PQMXDCBAXVkYaero8g\nWnnQJuEBAgMBAAECggEAS9KSrUQZYhBk5x80a3CEcLOO9z3DmSV5vp0bqlxhsrEU\nCKzgl3BVWjuVVQOimlmGhUqMUsXdI2aOHHGZTpucud7LUMKDvZHZrvzjwKdc3dHe\nOAHdPiDS/qpkExAaeW1UkWxQC4U2Ap9RBfWg9Ci7W6KKvyj+BcdTZC1rPvJH3zxC\nM7dRNL8g/Zl28251kIOJw+4+WCaSIytB3PuLOBgBK6A52hh6azOyxla/Ec/ZisVf\n+SgTDOQEaZyOl7jajPNgH6Zh6gXWSyAWismF9zN6Nl98YmjxOzs44f7XCmnnNBLj\n4MjBYU8ixrfDtMkdilC0PJVhNBEEU/U/T1ZYKTr8uQKBgQDm3y/7rHSiyvEJAGsK\nHdgy0nuOacNBjevaxMldf8HVS0r3F5HzqSEwDicET4iwB9Lk6ZFhceIHbXN3+xnd\neJCpEMUQKGuZQjedSgLfIilv3J8J4YLyEBsBBKDtAs7Y6XJqWs1p8l9ckaRQ5B6f\nJd2qXzoa77nTHy7pG4s7ssPoswKBgQDiD9Gqcr+3dLIDllOd1za1VHMq3Ic8dMfc\nL2ZITr72uMZMMWw9s6xE9D92nP564SIfp44PZSiEFQsj2u2Hj3CqIyAANBEq3Zyd\nImvF8qw5xwUS5i6inQUnhNpuZ438QLDzCIpoorSc9gwI8pljx6Ynlo0xBYnOiyPx\nrRz4Vl0hewKBgBIZmVdv080XgAImqD2ozVResBUgMZCoIb+LAgZ0h6vDNWNd07F4\npwNveJub6Kl0U5JixWUQWpoeeJkFdKeqVh0LFYgIwzbGvDl2nfcEZJ9Ceq15opx7\nqsJPrJYoTDMxSikQaV5PBC7rtCgrWkM74r8CrDmEZdspbLIV2Lqbb0SFAoGAN2vP\naC03qT9XwR9iooi3pd7tWS21jfHHacjJF6MOmCgWnWlkcwAQlNsYj/jPPsrg9ce7\nQTT4qOEL2R98KkbVRTDVUJdzOgIYukFNELt807FiIg8CkeVS9SxR7M1Adv5ZI0wN\nurdg9jYwHyVyy46PwpbbSJBmB5f/GOnq90sdC+8CgYASDdaZ3v74ZTRNMmnsIThU\nHmqR0kJAC7G+hsI7avgvN+GiedBaAJ7ok7JOl4Shu68cpBs8p45N1TtJEcIILVil\nM2E3UvWxcBoPPdI6QsEi6TGClIi9LyIYTh53Ixsn5LXFWkWLWUvXoihP5fHqA+Kr\nG4X5mUmNDsv6BTrWemhSPQ==\n-----END PRIVATE KEY-----\n",
        "client_email": "gitlab@sd-prod-cust-51f5764.iam.gserviceaccount.com",
        "client_id": "107634606372986580399",
        "auth_uri": "https://accounts.google.com/o/oauth2/auth",
        "token_uri": "https://oauth2.googleapis.com/token",
        "auth_provider_x509_cert_url": "https://www.googleapis.com/oauth2/v1/certs",
        "client_x509_cert_url": "https://www.googleapis.com/robot/v1/metadata/x509/gitlab%40sd-prod-cust-51f5764.iam.gserviceaccount.com"
      },
      "prod-ochsner": {
        "type": "service_account",
        "name": "Ochsner Health Network",
        "project_id": "sd-prod-cust-ddda252",
        "private_key_id": "f5264abd7218e9b167543157eb6144105f98dfeb",
        "private_key": "-----BEGIN PRIVATE KEY-----\nMIIEuwIBADANBgkqhkiG9w0BAQEFAASCBKUwggShAgEAAoIBAQCkU6B+EfLz8hja\n0iZF+c/16JRcsxARHqYmJPkJk1z6L3W1zWFKiL3GczjbNTb/uIaL6K65kJSNROCN\nkbCoXpoPg1tKZje9CcH4T2Gxmd59eE64S46kDcOg8JKlOLL9M9nK61hklXSUjJMz\nLEfWJi0tfgeJHrhM+V7WoYSvqrbFmwEGKjBxP3y62v+yTYN/z9u4V5LwPbBp87Fl\nFdn253Zwcx0MY7wKUWLpDlURkEwMMK2eVLTTNZ1ujXPpJ8aWT7BjxV9+/vnbd2r2\nF85aXl05+USoCdN1oDYBAcvNVXIjRbF8It1T0npUqDM8K9ICnaDYfMtfhIauo26t\nRnPYc8Q9AgMBAAECgf8PYaQsRjm9dB6XX+Deco7AUFmBJL1ChLK3wG1LfU/n9PHD\n7qbNzhTyfbSyJXACsEmnESRMq5w5JFAkP603HjEIW7bJmQcS16cpZD7qr5Yr9Oz/\neuzRJkDEvtp/klinWUb+k3FhB5ZufVYDV4l2dCO2QPqGuKgfNww4+rfRKXDzui9K\noYrl+BPycPqhuBjVOa+G9bSLO6HCrofuNP4uT7ogwdRQuoF0C1jXFjRdnOaZM9OH\n1ZNmhCvXCRlnqODfLRC4c0eplK9Csw5ZCGJU4zxndIssx+JyqEz+MyKlxE+cezWX\nQxbTeAeNqHwW/vjmBBA8Xj8RGfEOqz8jw1icpTECgYEA5awyIigLet1oSw7MdIn5\npbAriETVEQetHh3xzMXVcP96h8FFThcce7fIW8Iq8xDpuQsdt2+FHDtweQ8uyzaU\ncuF4xaT4qCGnTEwWwrkFYMQ58+bMQSi0y5Fwbae9HJoSYs1MOat2yXbM3yiaov9e\nXgYx9JclCvAoJun16tPwmlECgYEAtynWGCoiXRQKWdk6lfDjjRFVDySnvChvHaMH\nZhi6l0uv0mCyIbctdUYemaNfzKt2POYq5Mc+08fh26cKH9iYdG4GO9/fL1ltsWxc\n/6Ig2kaD+roHbWm6MF+ZVFu/41kib6aEd+vzoQi78jqzHkXmHlU352kVQUGFgpnM\nOEORZC0CgYAG6EXdtxyZRl1jnH9Tpe6nKAQ3JfLOf0+aj0X9ZZnEuzpBKPPfpFxL\n8DwLXmySQNj2ornzEVyMe9EnIujCXdLbHaLXNt3vmIuChOtOUegyf9RKXI+aChJd\n6PLbqWbLnGqfJBN9zIVXC8afk4YLDukUpNUNyRnpGU3r1a96r4MV8QKBgQCpDLTr\n8p9qtt9i08ptrznl7jRKWLNzRwJfISPoCDTXBOUOz245Df6nuYLunEoD9dCYHntC\nUd7byiTb/QysHdV/yDh6tIIiCnDiizg+PB4EN4cCNwY55pYQpXs3z8KIC7FryQ2E\nPULLwursg17kNvFP16lULLmQU+/sfRRubXZYNQKBgHGVYTq5syjyL1AqH7w+EnFj\nGPd+/xsubOxFWEMBaakAEMqBQEw7pY2TUT+NFBmuYl4W27B0iPddNS2UNTk+s3yy\nmpbeNx6iAYBNbxRIratE3tahZtsJOa4L0TOwU4OdVzU45t3ZLivhnWRlu+VJLBfo\n9MrH2PpowQAouAHHjAy/\n-----END PRIVATE KEY-----\n",
        "client_email": "gitlab@sd-prod-cust-ddda252.iam.gserviceaccount.com",
        "client_id": "111762336176501434450",
        "auth_uri": "https://accounts.google.com/o/oauth2/auth",
        "token_uri": "https://oauth2.googleapis.com/token",
        "auth_provider_x509_cert_url": "https://www.googleapis.com/oauth2/v1/certs",
        "client_x509_cert_url": "https://www.googleapis.com/robot/v1/metadata/x509/gitlab%40sd-prod-cust-ddda252.iam.gserviceaccount.com"
      },
      "prod-camc": {
        "type": "service_account",
        "name": "Charleston Area Medical Center",
        "project_id": "sd-prod-cust-c7f2c9a",
        "private_key_id": "8c8df7a2e571043ad1ae0a631663101765692f15",
        "private_key": "-----BEGIN PRIVATE KEY-----\nMIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQCodaeswNVV/61B\nUdIsWKBOrUaxlp80OEaMdQNgkaTP/KD10yrgVBg4F6z4jg5FGHWTJB6NVpEX2lh0\nfuhIPqYKGEaJ/wQZA1wELVLkOzeybUPsywax4vsGrBVtMld7/aNDxeEAvzB/76oe\nAMXeVdHDSgvCKTcHAAlPULNdsHuP9yMcB2ElPBLVyjuNfERfraLD7aRBoYoqk+QV\nxqiqjIyhS61bUdWHhzjJqX3dBJHT1etV2egwUZJhLEgp4iN17XxEBBxiCgpYBetz\neDgDAwncg5P9JudfAI2FRJLjquzKcfl81aHsA+YlEIbdOGLHVvy2euQdPUd8XXGS\ngDUTHAVBAgMBAAECggEAGEWphOXc5zD5Yuu522tin6tuP+i6pCDGo3a5p5iclV4q\nR/km2J8tRSm+IasAKdr6F9sQrt8t9TCf5Zsid0RVkqdIRJpSmhMGl1B7fMh+gC4n\npw7itk9EyYOSa8Mg4UvckgeJIZ0qjQh4a63DVMNrP5bj5lQfaloKPVwcl+91gjrG\ntGRpYJ5+w+BCui5I3w4Xon+b+PS++ytmOUcmkccNOMcr3ARM1hs8YC8RCXE3OxNR\n3EzBL7AiwYkoIxKUx1y+4z3h6tZ/CMOo48D52moldvQAuWzdqPsC1rAeNw9WKEnp\noGj5x5NeI4hBmXuyVIgQfiv+hK80XSMqi+SlY4y0BQKBgQDl4pZRZQvjOl0auWHY\npYRyJsAEae6zMIh+k91ANiU6HC8ZZFbuIPdCXk++eJTCzGa7oh7bXugDSc+iQAwI\nO+KFrni7FkZnNxHl8IdCwXz70xrt86RxPQR6zn+02J5UEmAaLJ0JXxkGlXy4teyv\nPaDhXiuGkhEAtRoMId/sUuaGxQKBgQC7mLgeRkSTjeynheihaSskvKyeYjOTq3la\nk7ha8t84Oic/xiY5XaCdp3akRkoevYP37N0KYS29mFf5PSYGraYvkIPOGVVy9ZyG\nMerqjNgWpEehMWvEd3XP4WwTVM8yyTconm0QSJ8bQuyw6jNMyDKcvPc+WQ8524xO\nPQJBOOdMTQKBgDpMWCqUIYuihXH9iV7Tut7oDNIRu0MeD79p6DYchRxTgW5DD/ib\ny5i9KiP3mGI+S99RodmoG9aZpVpUO7jq1utn754ibABI6vURJnw7bWK/ipjFrPI6\nsyQhsik+tghEELMSlVCHGWWeC18cY8WdL2t34jniWQWA5/YjsRHRhDyxAoGBALS3\n1Hblvl5fFKH1cDs+cx962tfhRkp1n/xP3gNPgRZLf1ACdQRCW95EWxi51eFzNFR8\nTBwZrdS9k9I4xA8e7b9YeoDrCtQqI9Vq9SFq+SgmrhJs56JmaooOYOzmgIwuJwLs\nS6iqLQw4Fof/tde77HXt3EilxvM3uxwLzcZDKR7pAoGAMp50LSO/C2xbipN0/YfV\nQZ8og4wgC5pgN4Nd4kG1bbJENczV6MxzvMevpQ7dWk2YrYmzuamY9gcCD4uzXUMx\njSXYCm/VJGHSYrmGyvJdl/WtfDWRQ6DCLYuo08Fa+CEwnGYXibthB7X90mOiyLt+\nS+2V+MNE5WF+hgPqyD5BYas=\n-----END PRIVATE KEY-----\n",
        "client_email": "gitlab@sd-prod-cust-c7f2c9a.iam.gserviceaccount.com",
        "client_id": "110621836983793102062",
        "auth_uri": "https://accounts.google.com/o/oauth2/auth",
        "token_uri": "https://oauth2.googleapis.com/token",
        "auth_provider_x509_cert_url": "https://www.googleapis.com/oauth2/v1/certs",
        "client_x509_cert_url": "https://www.googleapis.com/robot/v1/metadata/x509/gitlab%40sd-prod-cust-c7f2c9a.iam.gserviceaccount.com"
      },
      "prod-geisinger": {
        "type": "service_account",
        "name": "Geisinger Health System",
        "project_id": "sd-prod-cust-285718",
        "private_key_id": "fb3670374bbff1833eaa6dddb3b2a9e83e722d3e",
        "private_key": "-----BEGIN PRIVATE KEY-----\nMIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQCoYmfn4zjv38kz\nvzHfyjOgIVQ7Qc+hlt6iorsnjl2S7CqT898GJC76HqL71CckHkYd+nmyh0ThE99V\nDCVcQH9rU1IUqZ7MdPta1Bo+j/Xn85lxdO+toEwHRbLLYpCz7kw6CRQNZH4qNHEa\nUIuC/VeKX6p2DL1aFUNHb8DK7iE0Ue+4lp2+3gE5vJTNU3pFTShyn8+ljzqh/Hqi\n6DD4GZAAEBrEnUN7HwLjLNazdKnsevH/hfyUVFC1AVzeyXroaFQYftqmMH/Cu+Y/\n17IyIlbbrNjJUFJEsI3pq1xlJINl6LJmUsBN4fxgi7eIfYm6FnjiCmEDlT3exJhx\nvYjfZEH3AgMBAAECggEASkamSP7NRdq0WTQ0nuAF59Pr4PvFyCTR7nHUFr3Q/xdY\nLM19iqr5YfGTuXw7C86i5WRwvFS5CIQIlu51qHz29/PYGC8+UjgkDEvVi9VbBsdI\n32hStYcpImgXV2oSJJywWUVZ6BJ1KM7bOV1gPRbdv7c1bT9vY0ZP2DoulBV45ier\nXq+TmTzBOPb7EQbgRbDBJ6RcX0LUOWRevM9VYdl4qZBmo4JiOAkV3OfyjJ7zVXEL\nPtFbHIKN9xiQR++D44CbLYT0GP8eOgN4/hdcgv/4tik/27yir0+FqwLeSmTuR+vi\ngdelPOXc1NDyqkQ9TjlXK8YADTZ7CpQFQprKlyjZEQKBgQDT60CUv/OrB8uc2Nhr\n7nlNhNkjM4gvi1WmarjlMx2sJnlvF06HRnG1k3jovRAPrSxmKb3VTakUGjeWHWQB\naaysM8zygUIVjHJaJ6+24kO1vpI/wXiHRzc5lFVeUInauS/KVYsoP9MZQTX1vWhH\n67Az0GrVGcQwIz9l4Ne8sTfFGQKBgQDLaOzrWYCyZlPvmrSWM8BNzBXt2IAHrV1o\nbiYVreGutWMhazg+tCW6JNp2zvJcGMhYhzz7pZVwgA7YT61vVnjc0h/C2Ir+XsJO\n0/CqajpSCLXXVlCcpoMouYnfm1h1gDRxq+7tx2r+jOMDf45wEkv5hcucx+whkWmW\ngJFgqKqRjwKBgQCujhJt9AZjlFI06bCqho3IOmsYwbOlZeGw6CtQL2B7kl1oEgyP\nBIlZBRYMrt5x6zStGijRb9ybQFUVAzSCrWabmTdxcOLcGQmAFTx5KbqYE1cnyNbc\npt5oQTRoMXK7hNpGkVvEVX4uSTaHKfKhexNyOnQk/8pH1GC+LAvH0zma0QKBgGLR\nIHfdTVZdu90lDSIDFiM1SFn52YjhQgWfrUTO6lqTqAruKa7cmY9qo+8f7kGrkHjh\nilC+SnES5SNU4e661ZCBTAi+ZhfZNRBSihJf9OExdhWcyyCvC/zW1hIZR8oddFzc\nqAA/5syu6QIkD7CGUv5vb1FFFoPgPqls4DNS/TolAoGAL/pxZdx8B4D0FsH0OZFD\nYzrYWTjV7J4XurtmzfIOF6GDwOeiCuWWpU44IOi6OW0H0zbbjMLqLVRK7rIbR5Ov\n4UDduB9iD+awCBDZuF4+oxezcovuAhtFzLOUV8WhABoSa9rkdhGZ9WBUzvMJ4fvD\nSCOZCcXAbl5FaSJkpSCdLfQ=\n-----END PRIVATE KEY-----\n",
        "client_email": "gitlab@sd-prod-cust-285718.iam.gserviceaccount.com",
        "client_id": "109204188524725169816",
        "auth_uri": "https://accounts.google.com/o/oauth2/auth",
        "token_uri": "https://oauth2.googleapis.com/token",
        "auth_provider_x509_cert_url": "https://www.googleapis.com/oauth2/v1/certs",
        "client_x509_cert_url": "https://www.googleapis.com/robot/v1/metadata/x509/gitlab%40sd-prod-cust-285718.iam.gserviceaccount.com"
      },
      "prod-vmmc": {
        "type": "service_account",
        "name": "Virginia Mason Medical Center",
        "project_id": "sd-prod-cust-279918",
        "private_key_id": "ab4529771c3cf2c26a465bac109e05972918b64d",
        "private_key": "-----BEGIN PRIVATE KEY-----\nMIIEvgIBADANBgkqhkiG9w0BAQEFAASCBKgwggSkAgEAAoIBAQC5biIdy8UPRDLR\ntcGfrqvpSpHnTlEgt2qCoEF1s+T5xc5AWx0MSEIPwIKFiniaLC2NL5rIuzBzXZYe\nNnvMJ/7PxLzZFKFNqpzB0MVzFcVceA/e6+IC7fmNkdGjcU9qJQgyPcYj0n/Aqqlo\nIWwi6zFcYPekZicwI63NeRXgyJ2w1Bl7S9/AtBca8FL5WsvLQguB/wyfPODk5YD3\n6b4ILBXgIjrreXLn9uRomocVH8DVxomr/3tZgF+pRHpkL9jVqkQ57c9K7XOtCQ05\nPE8kseAUdvFuxaPQ79WFyJDtMmzmBMB+a35gpkBJv0Ubiyjsx7t2YTtUsNWr9eOz\nm/2KbwaRAgMBAAECggEAGWhx3EtTkSfzHrEc+thgTfMd0uf0TdtUCNoUniAsO91S\nyZaygEBWbPof6FR0+gsbosDnIKIlnWkxbKpihDLmw2ldxxEAmTfLivMkWwScqnL5\nGb6QlX4uN6vHYfHgw9aXLOsx/y6Uur2nwNDzlEVN2i6fQDOPIxWAdyskSEy07sml\njlNXu+DauKD62EXTFLN93awTs65umDKB+iPQh84du2Kn8QOlnsJpOReXGjZRI+Ni\n+ZrFmHV0KEpZ+tix03n+LqjVndvkrqLG/7+Oz5vtg1hPcpRTblqSZg60IfswBYTw\nUNENmQzNGkUefsva6/dR41adkMqVFGaM3q6uobFp0QKBgQDbnYm/q7j0BG4D+ImT\nW6IRyYZFHxCqK5ylvqn0I7MMFliNKhcy9PDtFgA7vlo9ILme/pbDlZspr2Ut5bZ5\n5IrXYMYIKbDsOfiR1kyyX4AcK2Ny4qJhadwlqIO9jBlXAyv/TF1YTqSr0Ubjl7Tb\nIRoA4iQihIxLKEVax5w9cBcY5wKBgQDYJrZveEkPbqnkdl1E6E+Co4x3r4yBVtgh\nrSr1dpXYJFFH71mfUyaUW5aGlXK/DfrRcr9KUTUD3dz0FfH/JpvJMeZQ6q+9/5Dy\n0bOPHDxzxGuPkdiC+1/WnfM7IM3Ej837wexw0JkQ57OKkZFhTM/ipGqvAk/vAT+b\nln0IgIOdxwKBgHkEvO2EIW1GK6HAiX9MVWjb1kOstLAxTCcBhAHm0jls7usLMLGB\nzGm5X/OMF4nryJ3WyRmKDXDRGD5MgVt2FxRMXZQN9VKXN2SV5Lm+aogbWLfsL3Li\ntM+DpuUCmMwcEVijqiSJYbDUp+2uoZjHCt9FvofO5shbrqwxpuUsKW4tAoGBAMT8\nUtq3lm23/qC0tj9sL7nw7rzMgZIF4SafuDzHe7vlLSYPnW+zyb7+0OmKHW68gM6M\ncoR6CYSilxDjRw2u097B2RH5L3xKAoEoVymfJAClCxlmYxjLmgXvIJHMTXpLS/+a\nNa6CjFx5rIgmLYWtCO+Vw5wG0W4RhzR2KzCqXkhVAoGBALIY7evTgMGJhWn9nPLi\nsDUBaKNivKLRQ66xEqvC2U7/S52QM+trNFkREZaVjOxGRU5fbUpb3XCoiPucaguA\nADI2mJhWCVbFFrxRNJXm1XgiEBRtNhlwuL9fXpcxlNmdql9lwRWZRgLop29NH3P0\nZjVehwHTB+MvLYO4ALXUo1VX\n-----END PRIVATE KEY-----\n",
        "client_email": "gitlab@sd-prod-cust-279918.iam.gserviceaccount.com",
        "client_id": "101644421921442143682",
        "auth_uri": "https://accounts.google.com/o/oauth2/auth",
        "token_uri": "https://oauth2.googleapis.com/token",
        "auth_provider_x509_cert_url": "https://www.googleapis.com/oauth2/v1/certs",
        "client_x509_cert_url": "https://www.googleapis.com/robot/v1/metadata/x509/gitlab%40sd-prod-cust-279918.iam.gserviceaccount.com"
      },
      "prod-eagleville": {
        "type": "service_account",
        "name": "Eagleville Hospital",
        "project_id": "sd-prod-cust-296201",
        "private_key_id": "71f59a2f8236c87c91189cab85c2d31b058edec8",
        "private_key": "-----BEGIN PRIVATE KEY-----\nMIIEvAIBADANBgkqhkiG9w0BAQEFAASCBKYwggSiAgEAAoIBAQCOgTPmPbv2B8cr\neAPGfYG90V0hSDdIiuXCFV19+Xxyee6mEPE4u8y01sTcTVqrwkZP8uYS2glu/cwU\nBN0ItDq00iR7SDcvW38RFxXny0/N52O+ttCIhi4kQLswuqszOc5Z7pcS03rby0bG\nSRfoc+CTBmdl98UGycnlDwrlEbVEkHsMeGswS455q7tHTBDLi6qf4my5+YsHO4RB\nogXfT2Cavr9zyW32n3crfyHPsG/gNSbZWQzSMVMmmyoOBUMgMvQSohENcu8jqin9\nUMzgluAxTuWHYSlU88sm6UZ2NJyPiSm/sZveO/8QhEd8aztvkJOLp3aSnGPV4tc0\nRHCx00onAgMBAAECggEABvQrEislBuZuQ6LBnGf3csnKn5skh3evqn7cZw9LkVJ9\n4MCm5LvVS2XHiXCE4ijCwZf6viTBnRbpZHiwSIJy8uvmN9XYS4lNdAoKWZtEM6n8\n5guJtX/GtKIsAD67p+5EMYNA//wt3JfYxkg+0GsHJGWFmiYzCRfDsFfBklgA3m2T\nytKnB/zlacHgmrr4fj6l7FWrwJ4EyA3zqPf+kMgo9rBC6CypT9UsgajhXw+GEwS+\nm3MDG9Yw8J0+sLME+uDLwImfglGFWwYUaQsvpweIWzqPA6gBMQZiTXOLONvPhLs1\nvt7BTihUF5QtcF83J5IJdQeAlgQHxAk7Y31anbdywQKBgQDHr240IKDm3VoqqIQh\nX47xYco0V2PGYM4xQ07I67ZmO4fT8ZpKCw1xMT3t47oGSZpG4LfzSKOv716XKq+z\n9GMiqF5gJFTbyqWj63jb0eES6TtiK1Asqa0C7nohA0ndASluiMEdP45CKCsQL66L\nYReq2hX6T8ysKTH884E56CU78QKBgQC2sYmBndde33UUA4SYY+QOBFS7Ke2NFusv\niaPOQqEA3jxlT06PoMPJjZiI8IinLvRmmY9xrzuaqn5uOsWnrLXU1J7xwX2FdJmf\nRox6kO/OqVgEORU96E8ARMYv0rv4BzDxpGhlQD+6rHMSHNCT+eJVJ/cuArjXNX8b\nhBrwvQbflwKBgHfSn1CMW8DtBpQedtsH2mCiC5II9ogtwszw1K/gMZ17D3Bww+K2\naySHJeBADHqFZBwv8HH+HqsN+ja/mJtlhK9hVPBgHMOfAHIydF2DvYl5hvbLgtqq\nHnVp4MM6k/IYJmJ7kVYZsyWjsOOPmS9t3aPaJeIETI7qUWK2Q88ZrG4hAoGALbsx\nNX3iTIBZGUJsLY1Lp+qTnyo2qYukIYkttNLe5I28xotcmf1fJRPJq/Wx70+EpC4q\nRwXbQLMO5N7XXUqRVY5HnzKVDhQK5GDPh1rCfJPNUXlMhOui4ulURuJUZRi/uq0x\n6K51hW/nehA2ceQqMLDJYf4ScOSWRbgC2mIm108CgYAqUu++RDN7KPnl2WnuHQAk\nH//wQNKn4UJ+MdDiZ/LPsh86y0H29uFWr+8qmaxcfy9FvLUc5ORrKdIl0be0oHVt\nSPmJvJtVUr/LVoM4SvAKPeOA/Uw9IfYXuR/fnenFOe1L3aqJmL9bTUSkUiW3pmLN\nv7ZN0D5M+43nH3EXh00XKQ==\n-----END PRIVATE KEY-----\n",
        "client_email": "gitlab@sd-prod-cust-296201.iam.gserviceaccount.com",
        "client_id": "116017746227109537788",
        "auth_uri": "https://accounts.google.com/o/oauth2/auth",
        "token_uri": "https://oauth2.googleapis.com/token",
        "auth_provider_x509_cert_url": "https://www.googleapis.com/oauth2/v1/certs",
        "client_x509_cert_url": "https://www.googleapis.com/robot/v1/metadata/x509/gitlab%40sd-prod-cust-296201.iam.gserviceaccount.com"
      },
      "prod-baycare": {
        "type": "service_account",
        "name": "BayCare Medical Group",
        "project_id": "sd-prod-cust-296108",
        "private_key_id": "647cd8f5f58b18fff24f3efd0ba96255a1e9282f",
        "private_key": "-----BEGIN PRIVATE KEY-----\nMIIEvAIBADANBgkqhkiG9w0BAQEFAASCBKYwggSiAgEAAoIBAQCs2JRxkyYiz5UY\nXIFFADQbVe5FmpLxAAD8TUMx1UkGMsHKRgyfTRqFoQkv4ocjYUeydHmafw40YCys\nKDo66xhn4X2Q4F+QhWwDQ3ex6C9yq8hSJIEQaKMaJPEvLK11IWGTqRIizlPS7M87\n5ihvo7KQYzLzSyj9THVIhTjuI16bffq5T863B6CeI4pgJrqqNHuauuMfqnOc/WuH\nzJ3Swpa8cWihFcETNrNttUdSMErOL8cLIWH4IauO67GEW8pTJRQErjOW1QO7Qx65\nEaZQad7Pnpr77vMKOl/b57mMjEYlqOxJEisbE60d4suj6s7kdVwOjQMebX32EYIK\nWeG5GGrFAgMBAAECgf9lYRkSv7JjfYUwxMJwBREBHu+O0Adfydvd0XNws4GD0mjr\nnbRnHb6YBMhyYVf13qLz4V1KfABPBt7eGzBbD7DKxyi3qafGMFQ95I+yjqRZikDh\nANcBZl5ECxgwkz8Cr6VQzxTny3+GXxC3wpYcbtOvtURUgtTC+BWDrseOIOH6UMsk\nMtPmqAcZNxrUiJVIZd/cI/+uGFVsFgYLRwZPjjaFNCxDW6lUdCPdn1hg5JA8iK4D\nbXlMyJHCNEM2ZaJ+ph5IRz76sUszopr64FT7tv/uE1aE3ReIisb2l13NWySWITdo\nR/JReYSHDTCkBKgQPe2JchAfNr23i7G//QIGZAECgYEA4qdGmKfkO35u0nju4m5+\n93sJt/JVjArUUjSIOmiTNWnfCqys8zMqmz8Of4SvZqlR2rRufnyzo2Vw1J/zuOcS\njQ97HY2TVxDGGaYhST5nGgeXI+44UJTXCPsttEygaaKxUTgAdzVKu3MQY/GtAwXZ\nHrEKyZjOidCf5umXHs8HchkCgYEAwznJhq0h8VhXQcr7d8JuCO4XtGadRkusqq1g\nd8n7mhx78G/2knkIA7QKOV/7nPkHYKbHLDV7obrIunoaCI68f5mByrY2t5mxBVYu\n6eZj2Mv3ZtOqLuV8xu8uLgWCYZfhH0QGNs9ta6F1P02O/p2aax6TsZqGqaQrAMmx\n/m7Di40CgYAOE2FY/aQ45aMwQv5i5YXRDL0PxWb2qX2CFW0YY4Iw4QBPA2ZCPD07\nIvFdjfnuoDlLIcVy6X+meBhHvB9ArmkChaGZtj8eOxZyZVTP3aY9gZcrThIFPxkT\nn7W46sFTjh0A9D9jJ9YaSg0Mh+e8tlJDi68l0e2RtXkYuA0Ahs7pkQKBgQCdgFyW\n280bkiLrgKB6URFHARd8wqC2Gs1V8YR3gCwNfFd9dkbeiuitXC43dblgPgT2s/bS\nMt/obfINh/7C0q0+4JC3A2SHoWYQoSQ+LlJ1kLCrc67EIP8W29ZM6ML0i3zjJZVB\n2P01LVCTXrYzsuDm9QdA7SMhb8IF47NHSwNV7QKBgQDMSC9Dxqir27y/R1R/jlRb\nXnlnE7QGftnGYBieGKs8zbF7OapFJGkoXrp5xH6Ltx1QhFw3qf4eThOGn8LSVmEp\nz6zXPCqzXCVfbFEUz4B3/EMbad4Ujj7Vr4Ye/59AaoZYXnb0RaZ/tvlL5IJ+cHzo\nAznxhepSFFvnk5NjvSc9dg==\n-----END PRIVATE KEY-----\n",
        "client_email": "gitlab@sd-prod-cust-296108.iam.gserviceaccount.com",
        "client_id": "116220091779427536570",
        "auth_uri": "https://accounts.google.com/o/oauth2/auth",
        "token_uri": "https://oauth2.googleapis.com/token",
        "auth_provider_x509_cert_url": "https://www.googleapis.com/oauth2/v1/certs",
        "client_x509_cert_url": "https://www.googleapis.com/robot/v1/metadata/x509/gitlab%40sd-prod-cust-296108.iam.gserviceaccount.com"
      },
      "prod-chco": {
        "type": "service_account",
        "name": "CHCO",
        "project_id": "sd-prod-cust-05f7671",
        "private_key_id": "2c90aba0ad97b2fd771b702fba95b98b8051e29e",
        "private_key": "-----BEGIN PRIVATE KEY-----\nMIIEvgIBADANBgkqhkiG9w0BAQEFAASCBKgwggSkAgEAAoIBAQCp8SbquU4NoUMN\nmx45G/deCiMPrU1ESTmgObiBesIhuWbqFImc+L+Wog1IaBI7RvnilAtUjnfY/8Rq\n3Kz0Rn2/fzV6hPBR045x88EpGxbsz+ATFe8RkWPsDPxjxJ18XO7SVID/VXllQ+v1\nufVLFe/BdXYjrI9bbMTofNID0hTZrn2PSePSa+FhHQVL3C8+y0vNAgWftEAU7Fm1\nPptSoRh2BGHz2zTZB214sSr7O8+hyYGtggQ+awLMLne7/V0amGZydwyVMsmp4jAn\nWDqibaCgSZLPAlJB4GyubUy/xsswxdx5b3A19vYENF3k37I77bt+EFqsQ5xNleoA\nvbfOnCSfAgMBAAECggEABdaakyrNkytMne2L0sNysqDfhnzaMDPhzm7dN1mLzNPN\nRNJyzdk/38FuemPU6INuN0oFv6BrXqGe0lsFeBjp3gfVQwHJLc56UNDifHKJTZZw\nqMcyoORhTe0+SIErNegNbl1n1x3NH1z/gMAkPLGivH84EU6u2CG6/A+5uVbgz3+g\nRsXp57eJXGxRfbDB9r4uBe65108bL0iMdbwiG8fu5Ks4TV8OY9VA8kBirEYPK6GL\narZWQas3UpQoIxIn8RqopEseowDQ/Kie0Vgdk1IJiKrNrUaAFB/OhF0ztJ1mn1S1\nlxBBX7uI78f76zIQ1NiY/2MPK2Ha5OCaVfStF4+TOQKBgQDc35EPnVbTGIo7b6HM\nBZQWCgOIVQA8RxAxCZls67EKEZLCAxuN4w5ZHimuM3gUCc378DAcjaQUeYnE65H3\niB5YRIt20rx4ILgeooGMbfQ4pNEgVCHnLridvEuM/E/JdVvRzZ7QzCSQfBfE50xM\ny7W4ejqgeKmFhXwDWd/ZCnm0GQKBgQDE+APLjRgd7A5y/a9ej8iMOCK6r9ja4kzA\nxYB5K8BIFaKwwgiBZRmuIozXCqbb3Qgx2ukTS2o1PUUM9DPLiIQ0KUkc4WT65dG5\nhIFMp+0ANLzp26x5GlTul6gxmZxGJ4HQe0ghbtXJ1nYdfWDo1L1Jy9SG7bqwpp3F\nYyfifcp1dwKBgDxBhZqD6wbl3/g9CR/WucKVcsdDoS/Qlzs5ijCrz/2WM7pYDLFV\nqzlmwCo1AmI9FoPPz+BKR+QKECq3tXeZG+YqtP1ymlYPXwuEe+EqsgCt/htauHEQ\nTu9BSj8pKj8klCsVdGkz+BmusPFXxHoC7Q1p0wqqQF0bwGxNnYLhl7gpAoGBAIF2\nkugDwUpJkeW7IL2CvEqNyyXx1xNnkxNRvXPncebvcZPEtVp3XT27r97YPXxd97jt\nLi1kcFa2AuDU0gaBoIO4bznwXDMdQ5Awq9v6tChigZkkrmSbDiAUxpRzZuglp4mk\nvnBbLWIQ+GnBsyTWT+RleTeGoiLiIOlQlzKjg6NZAoGBAI91kxK78bQySaGnQOIO\n143nrN878+aZz7NkChOUl8zJfimQaTH/UByyP/UQtbDzKROBZHuipcN5vjxkzQ47\nDllhHwZKjV0WlNrlZTQ6dSfy2pkjiBjPuRbTaX5ByC2Zi4aXrsIYmkMs65X7DZ+V\npAJ9iYv2brRrNogoZpuS7gZX\n-----END PRIVATE KEY-----\n",
        "client_email": "gitlab@sd-prod-cust-05f7671.iam.gserviceaccount.com",
        "client_id": "103608502495606791073",
        "auth_uri": "https://accounts.google.com/o/oauth2/auth",
        "token_uri": "https://oauth2.googleapis.com/token",
        "auth_provider_x509_cert_url": "https://www.googleapis.com/oauth2/v1/certs",
        "client_x509_cert_url": "https://www.googleapis.com/robot/v1/metadata/x509/gitlab%40sd-prod-cust-05f7671.iam.gserviceaccount.com"
      }
    }
  },
  jwt: {
    issuer: 'urn:strongline',
    accessToken: {
      public: '-----BEGIN PUBLIC KEY-----\r\n' +
        'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAwbL6ex/LTzLogeUmTfC5\r\n' +
        '89aF2JS1j+C6mshloS6lK6pMy1lbDwuB8pl4Vii313cXmb+E6KqsNEYdLmlOcCV4\r\n' +
        'jb/mSqJs34ka8qIa355bcXvduL2IikKV5FBfUye8Hk5SyQz+kYujKuiiUqpbUnmp\r\n' +
        'sXbaFvS1CzB4EpW1xlNWRU5/PoRhHML5fcUDiF8ZhmrB2/VBdBrPM6oOQhsWCdTO\r\n' +
        'ZSzi11s+atWqPQ9yDiPxUXgdVUAs2CBFmsyd31Gw66kbb1TeoRioL2NLCoRMoH//\r\n' +
        '6eg7qMomQBRc6qzkgsCH9oOYyWNtupR+IKk8TK1rjDbD8kW7C0z6M1vvAYXTZVlr\r\n' +
        '4wIDAQAB\r\n' +
        '-----END PUBLIC KEY-----\r\n',
      private: '-----BEGIN RSA PRIVATE KEY-----\r\n' +
        'MIIEpgIBAAKCAQEAwbL6ex/LTzLogeUmTfC589aF2JS1j+C6mshloS6lK6pMy1lb\r\n' +
        'DwuB8pl4Vii313cXmb+E6KqsNEYdLmlOcCV4jb/mSqJs34ka8qIa355bcXvduL2I\r\n' +
        'ikKV5FBfUye8Hk5SyQz+kYujKuiiUqpbUnmpsXbaFvS1CzB4EpW1xlNWRU5/PoRh\r\n' +
        'HML5fcUDiF8ZhmrB2/VBdBrPM6oOQhsWCdTOZSzi11s+atWqPQ9yDiPxUXgdVUAs\r\n' +
        '2CBFmsyd31Gw66kbb1TeoRioL2NLCoRMoH//6eg7qMomQBRc6qzkgsCH9oOYyWNt\r\n' +
        'upR+IKk8TK1rjDbD8kW7C0z6M1vvAYXTZVlr4wIDAQABAoIBAQCLGpapv26JhPzW\r\n' +
        '9BKrHHrecywSGtG87t3z9ICP3Wi+0AFwc85iIpiC+dYiwgEs2pj3UEQJtzAOJmAk\r\n' +
        'iXnMLgzCd5LR/I2Fhvf0UZhNCXOQLy1O08ZvzFvK6DFdDYcamJL5UbysF4TpdsHD\r\n' +
        '7hXBSCAmkz0SCVDPJVLFvgAZ2pV1FSXcGl2RIW/BJnPmK169Up9FvVJAU+gJU7ck\r\n' +
        '55WDg1l703daYuxQhnYUsgusbZMewq9HXTs5JFPswFxKbE/LD+5A2ZGUAG48IInY\r\n' +
        'yIMi9R2+zfYn3spk9mYNoih/wp33P3i3swz1CfyA8mXQlFAOJV/sRZkiItm/7tlk\r\n' +
        'OAz55wPBAoGBAPb6OY+YZGHyVeomere3pi+mBIyioIurXIC/wVc+5eDHt4ZEUibS\r\n' +
        'CGfh8W1buH+JAqIPRyBEH7tL8LxP7ikWs/M8akaF7sxP8eZtMZLpwoa8foggnA4O\r\n' +
        'Iuxh7/Ukog1DwWcM9iTBuo2h7YtuVRD5JTwRi/vMaypmMSBNv48TISlDAoGBAMjG\r\n' +
        'fFx6LR9OWYQLHL6TjccpAgI8z2cokqahP0fn3DTIH92umxLH2lOWNBrzsfpnu6+q\r\n' +
        'DY1S8fv/48oiYHTK1i23LHCY89AWmp4VlBAFmPdrEDhF8NMwLpJW0KsEnW8IIDOf\r\n' +
        'Xxnr+K3oXVkHlcl+z2vbRyv2S34DFstmdUOThLjhAoGBAIHxOzG62y3RY7STVEOu\r\n' +
        '40f0kDYyJkf1+/Bdc2rk0+vFCGEjNxnnrbaCYRTJijPHsVP0t9ZZIVzY4qshXsvN\r\n' +
        'fxY9tiiutOpYMYyr7MZAKTHcoLIcfh7C/0rhaG/RO1XBTVNbtXG3X+C+zo1qmmUY\r\n' +
        'wKdvD/iGH+gp5jT50wyC4xKjAoGBAJwjuF1W6rZ5ASauWalgKRdkrdo9nnI0XHBX\r\n' +
        '4FW0XE10AUwuWQ0u/XyZyGXZdWUuvQis8CMjCWuYInd1apwJjq040cezO5FtsssU\r\n' +
        'LvSS1dY8nf2VbudehilqeJc3XJQXtqtO2u/W0qu0lLwDKRrtkrPncR8gSK90CYsf\r\n' +
        'YDywgbwhAoGBAMwo547FLNxXakKBRcsl4vEftjiVV6gV6a6tgpba+A818mI0PZ/5\r\n' +
        'cdB2ToTuoEdM/m10k1x5DHivTiA7rrr427gvim/Oq0tjoygUzZmEMi/RQKzGc+QH\r\n' +
        'XC15ZQchdbfoASnKA/feAU4Sr2wqx1z+REL+hdw3XQkyu2/YfxoZicre\r\n' +
        '-----END RSA PRIVATE KEY-----\r\n'
    },
    refreshToken: {
      public: '-----BEGIN PUBLIC KEY-----\r\n' +
        'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAi/k3AeRumoMn+zzcMjbu\r\n' +
        'bCrAeQke/vvK7LQApX9yp5seCaV7BBqbHxR2LikCU85mFhj+ZltzRbr/bjoJJpJX\r\n' +
        'x4ris1339WIqMHluS9WDaKKQGr6KjeWgVtH7aJRRKe2hEJI4VNZ9mUpSbbkH96HO\r\n' +
        '/P7ZJoRjRqgbnuOSHr2jxRlrsn0gvT6ZBK2UO5PF1dKFpJyyXjZhCBlq1cpjRDh6\r\n' +
        'aiLLdfvsu1C3j6mW+ftdaMS0DFLVVXzydFLvHd6v6kXidhlXvJM3r/4jyL9rlECR\r\n' +
        'QtIhYEYJB8+hLt0pTtZEc62IEjH3Wqvh06Rv3HcQLtKvucMAS3n0i1TwxfzqOpih\r\n' +
        'ZwIDAQAB\r\n' +
        '-----END PUBLIC KEY-----\r\n',
      private: '-----BEGIN RSA PRIVATE KEY-----\r\n' +
        'MIIEowIBAAKCAQEAi/k3AeRumoMn+zzcMjbubCrAeQke/vvK7LQApX9yp5seCaV7\r\n' +
        'BBqbHxR2LikCU85mFhj+ZltzRbr/bjoJJpJXx4ris1339WIqMHluS9WDaKKQGr6K\r\n' +
        'jeWgVtH7aJRRKe2hEJI4VNZ9mUpSbbkH96HO/P7ZJoRjRqgbnuOSHr2jxRlrsn0g\r\n' +
        'vT6ZBK2UO5PF1dKFpJyyXjZhCBlq1cpjRDh6aiLLdfvsu1C3j6mW+ftdaMS0DFLV\r\n' +
        'VXzydFLvHd6v6kXidhlXvJM3r/4jyL9rlECRQtIhYEYJB8+hLt0pTtZEc62IEjH3\r\n' +
        'Wqvh06Rv3HcQLtKvucMAS3n0i1TwxfzqOpihZwIDAQABAoIBAH9HzTdRSyoBnbed\r\n' +
        'rfWTcda2QXNOUfs3Z106iWP0soOqT/Qhn+0pbGkkqBptfApNIxNxAtR80CNWWbMX\r\n' +
        '3v5OELgImQDVsAZYj1io8xzOOMJLIJLRNRi298g7BUwhonRn+xAghkzPBkMADFht\r\n' +
        '6srJPnlcHIYj2EqFzyf6dAK0G1m72sFk9nfPJ+LwbkNT4qj+YWQq46dl6E34I+TY\r\n' +
        'U+EnyDjto+igk5qVZOhqbZNOrv/6hCO8g+Dx7AmmiPDJmfJmMyEvZuri4Obmnfs5\r\n' +
        'gwbLjgbGl9q/uMXjXGXVAIGNHFYuYTyckeLb3X+ykwecQNjsTFEyU7ajRxmKWZQA\r\n' +
        '1wAqavECgYEA14bNRPcinYZQ1DdqGlTnyB7rqmxHo9+TkG34Yub3GL7OszObmHfu\r\n' +
        'bHtAqta5a4XRR3L9wUQA/0WxxdIdQoOkZ4i+njuZvIa40WhTaZDKh7kCiEWjMRv8\r\n' +
        'NV2/PNC4zbqUQySRHP2msgUOBJFgtHUrr7tZeTN78wvbBEfMatVoUcMCgYEApkJI\r\n' +
        'ofD9rnzkTRSj/JpwD6MbhedBWN7f+Dsb/yNOsT+89WlAJzIrw/IA/c+/Vgadu35K\r\n' +
        'y8pkAFQ0xwMuXTBVAnFrkD8PyRQEfs/5yIA7hGl+6bHh1F2yVY0eNLOZQAhlF2o7\r\n' +
        'MlNFH3fHcFyKKSaYiQNBSjpElf1IlAQ27adjc40CgYBlCHo9dCSz93z4nqmMgnTN\r\n' +
        'KUGMeIn01iAUupiPtHntDbVT6ENua/pGvFiKPXJaZza96bfu23QkNoWpmF/DCHcP\r\n' +
        'WdkR0Bq3d+g3slI30HpAuYfoXcysftPyKGo3lhiryGUsPH4SOw3jsd7qYUQWFD9Q\r\n' +
        'd+DvoiTewJYBIa8//Z6ThQKBgDlZN9CUXgzoC6AC9KXXZ7wdGQtRP5s2Fa98RCX3\r\n' +
        'udcuEzHbwkNC7RHD0k+6b1dvJM1dvNMa1u++HA5hrIZ8e3ZCRUYWWo2TmBFeD9z3\r\n' +
        'TpBYC2z07N7iCvezm622HbCWtLgk7pJnYrp+CI9bfFTBeXAZbVX7T1klavmTkpbb\r\n' +
        'XFSpAoGBAIJcn9QyIDaiibJyS6kEdvkD235i3JulcXiXk11fLk6+C09dN331bMxf\r\n' +
        '2q7s9J+dJBTG6/BiHgk/v7UCZzWMWLkUmS9qMRNHUEPOueUI5rKNkmMacoqxp85I\r\n' +
        'RtoQsjmji/ospQrOl6wvbcmh+zlvBzZ/VQYx5gTza4qgmgkmp/2b\r\n' +
        '-----END RSA PRIVATE KEY-----\r\n'
    }
  },
  saml: {
    public: 'MIID0DCCArgCCQCT4pOASgh6+DANBgkqhkiG9w0BAQsFADCBqTELMAkGA1UEBhMCVVMxEzARBgNVBAgMCkNhbGlmb3JuaWExEjAQBgNVBAcMCVBhbG8gQWx0bzEWMBQGA1UECgwNU01QTGFicywgSW5jLjETMBEGA1UECwwKU3Ryb25nbGluZTEkMCIGA1UEAwwbc2Z0cC5zdHJvbmdsaW5lLnNtcGxhYnMuY29tMR4wHAYJKoZIhvcNAQkBFg9kZXZAc21wbGFicy5jb20wHhcNMjExMjIyMTYzMDAyWhcNMzExMjIwMTYzMDAyWjCBqTELMAkGA1UEBhMCVVMxEzARBgNVBAgMCkNhbGlmb3JuaWExEjAQBgNVBAcMCVBhbG8gQWx0bzEWMBQGA1UECgwNU01QTGFicywgSW5jLjETMBEGA1UECwwKU3Ryb25nbGluZTEkMCIGA1UEAwwbc2Z0cC5zdHJvbmdsaW5lLnNtcGxhYnMuY29tMR4wHAYJKoZIhvcNAQkBFg9kZXZAc21wbGFicy5jb20wggEiMA0GCSqGSIb3DQEBAQUAA4IBDwAwggEKAoIBAQDjxXMGMAzUuws714Jgtd/hX06maB9hUZ0EHXBwuZxfuB1+pqx72+2dLqRJf6fjGSNJ6peRJIjrPbhauPC4y/Av437b3kMQPqkqGjuKZASipt5EFuYPkkmg2vzb3z9LnDYJbXs8m+Ovm7zUu2cs3beQXnhjEvu6huwun10yA2vfNhQRzq+rCZnVDscnPjLD3bi9jQvKuVx8ee8k9Mh76jpj4cDQrMr9Q4M0f8089+wrTYeCYW9Gnmk/bWfKDYRt93ixuzh0vHLe5/sa7KZfQKSUiMBNOwOW32sfnqfjyZADP9iE70vO0XT3RrDla6ZOGXmv0teh4DtQdTwRx4vt8Xa/AgMBAAEwDQYJKoZIhvcNAQELBQADggEBALdYa0REX6tKx3RcIDg9EcDj878S+2F4JEXdQloxB/ZIbtOd9p68MD/E8hGUgYopJIEPUQHfmSxm5XAV/gdOJoZKgmnEUhe7F0SZ1yt9S49A2mUlahOCt8OuVfhIADv/ojd6QOThVWT2Zecp1l/yYgDoUJkukjXn4VLwHaacUdQVe0Zv6YNZqr/sgCyfUGxKBDjdLuLMqx/WJTH+ruitE3PJmOWt3P6FQ45TioMqeQ4wR4qorv01dewiiEmxcVWdtgaXRJuRBpP9xoFSpf83IRUQ07h3XBZW9lNdkCV236lU0wd1ztcpgR22CPlIJZnS5sy/I9hVl03ed4L7lDHUsX0=',
    private: '-----BEGIN PRIVATE KEY-----\r\n' +
      'MIIEvwIBADANBgkqhkiG9w0BAQEFAASCBKkwggSlAgEAAoIBAQDjxXMGMAzUuws7\r\n' +
      '14Jgtd/hX06maB9hUZ0EHXBwuZxfuB1+pqx72+2dLqRJf6fjGSNJ6peRJIjrPbha\r\n' +
      'uPC4y/Av437b3kMQPqkqGjuKZASipt5EFuYPkkmg2vzb3z9LnDYJbXs8m+Ovm7zU\r\n' +
      'u2cs3beQXnhjEvu6huwun10yA2vfNhQRzq+rCZnVDscnPjLD3bi9jQvKuVx8ee8k\r\n' +
      '9Mh76jpj4cDQrMr9Q4M0f8089+wrTYeCYW9Gnmk/bWfKDYRt93ixuzh0vHLe5/sa\r\n' +
      '7KZfQKSUiMBNOwOW32sfnqfjyZADP9iE70vO0XT3RrDla6ZOGXmv0teh4DtQdTwR\r\n' +
      'x4vt8Xa/AgMBAAECggEAXSRxVSZxGM0kWuRWPJYjZ1ZCI2qQ6oslWnlvcal1eO8h\r\n' +
      'oJkoD75v/McGB8hcL1IoTCHSuVfnZctniDH4vpknL24ogmDxq8L0CnOPxZgBDUKP\r\n' +
      'wehbTCLWWSE0JfW1PIY00sYijzZwdW+KWLQKDR7d4fu/30P0DOL/0lD5G2qqwivl\r\n' +
      'lMqvKiGyiTgKqI+KAtAIJpWj4HP/jVshYEY/Aoh5QRzYjes2+5yVyt/lu/xN6F5P\r\n' +
      'TlvAqhijk9afilHqWoGrzz4YsgGKEWxy0tYIb7rbM+wiZE9a1Nz3Fi9D9dpmHjTv\r\n' +
      'B6awkbz6WwMjsCSwYT+EL5uZPKu4GuRX3gYOtIcBgQKBgQD4rLFnQ5xgp5B5Yhjp\r\n' +
      'yL3ZIMO4gbR7ROV4mBwHCricXXXNDSbAPCm4t5/s2dP8cxXO0JzLeb9hLp7pZoud\r\n' +
      '+/mVUZx9xLkNcO07TEWKNsdVjvA306rhkua6tAYoO07PrZeASEGsbam8bX2llI+r\r\n' +
      'K2Ut56iLrF0MtrBYUE6WXacEMwKBgQDqex7F/TKQ9DtTHRToNqJxgeiOhv7EKlWB\r\n' +
      'wEeRhUvx/kNbvfxKRM+m9WhaY7t0fUJYvs5uQHqj/ekOC5zoUEDFGvGRHX+wcZXW\r\n' +
      'R+t5BvirWKseZw2eiVKYZR2ssh+zG8ez0XxUtxKDlKkdKpIA4vkFLhvYfS7sgy7u\r\n' +
      'boCh3IBXRQKBgQCoUQN2+N100pZIgqyIOPa/a5zuWuSVdym2+YYnAgu40s20cA1l\r\n' +
      'mpNG0wstXrdMrXz6Ult+ojZ2if7Hzj/8PBxQBEBfJHN49JGuTE7ZYYCEmc5giyWj\r\n' +
      'rzH693UfcB1FFqNwEfhZxJRZ1OZx199ypVnDu4Rqnc0RmOo9rIW040jz3wKBgQCF\r\n' +
      'T3JzhjyVhQWlf0c/rrA0cHNTdRSrWpY6qqMF7Q78il6pbh5d6zlT5vppSYJt/DkY\r\n' +
      'U4Lx097mFZnGzntq6RWp+mOU6XyLSEi0BzetG3WNlV/UbSqlE9XNps90O+Eh541m\r\n' +
      'gpd5Yow4GvD7EBTqRCDn/Qr/zkw7U2n/TPv9Cmw9MQKBgQC7S70BfL4t4rvqOkhJ\r\n' +
      'Hru7OdJj0kI89zr65j5nhqobiMZW47hSokJGTbN9wWCPxBklgDTjknlcgHB+SSTP\r\n' +
      'V36JWVqABX3YirvVaL6sNPm/mrrHOqdKDBRCYbJQXZz7H9eXO8MN+T16RiHhaj/d\r\n' +
      'kGbBQoZmcpbFnLQJuWp3rB88kg==\r\n' +
      '-----END PRIVATE KEY-----'
  },
  sftp: {
    public: '-----BEGIN PUBLIC KEY-----\r\n' +
      'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAhjwuLrRRIgSFHsJSxkyP\r\n' +
      'G7h5rrxvjimHmDRJf0UQtEjPzzW3j2UYtXP5/7+Sbz7OSS1ky+POWMVWr3T2RA0W\r\n' +
      'Ec0+PNZk7s6yTE6oxochNF3iyeT8DHRoctWJz+OEOzSHweWeWOeDbrw+TMMRyNJq\r\n' +
      'CTllPVsPkX6KUAQtlFNlQDHHuhIAdwWPHe9ea8aAmH99YhEFUjoUmUa8u+GFDEd0\r\n' +
      'PG2ONXeZaCRoVr5y/r72zK/dZi5RfH3LST5l4GYCnrI+W+sIEWMWHe8MIsi/sXTL\r\n' +
      'JxQeIY1331Ucm3GoI/nb/l8AwKKMbdwWguHW2hL7UyWEWaRcKk5yxZRh07CvGJ5v\r\n' +
      'RwIDAQAB\r\n' +
      '-----END PUBLIC KEY-----\r\n',
    private: '-----BEGIN RSA PRIVATE KEY-----\r\n' +
      'MIIEpQIBAAKCAQEAhjwuLrRRIgSFHsJSxkyPG7h5rrxvjimHmDRJf0UQtEjPzzW3\r\n' +
      'j2UYtXP5/7+Sbz7OSS1ky+POWMVWr3T2RA0WEc0+PNZk7s6yTE6oxochNF3iyeT8\r\n' +
      'DHRoctWJz+OEOzSHweWeWOeDbrw+TMMRyNJqCTllPVsPkX6KUAQtlFNlQDHHuhIA\r\n' +
      'dwWPHe9ea8aAmH99YhEFUjoUmUa8u+GFDEd0PG2ONXeZaCRoVr5y/r72zK/dZi5R\r\n' +
      'fH3LST5l4GYCnrI+W+sIEWMWHe8MIsi/sXTLJxQeIY1331Ucm3GoI/nb/l8AwKKM\r\n' +
      'bdwWguHW2hL7UyWEWaRcKk5yxZRh07CvGJ5vRwIDAQABAoIBAQCCaTkc2sXv3KBP\r\n' +
      'z86HJJ3r+n8NSbi/To2TS0kUQk8GN67cyn047KjisHfzBXUIauYSpe1FQF6aU63x\r\n' +
      '7BnM50HHrbSHemq4r0r9Y3YI2gNiUswgBTzyBK3s0pR+ybUPp+s0bxY1nUZNcMi9\r\n' +
      '3s4KYpHtwDshLrSjT2KkdkqpzZOSlt++V/I7FBY3tZFaV/1/RblpemvaqmHlxvl9\r\n' +
      'rcAwFfjyDXTbTviEzkQl4C9RiiU4b9LtN8PQkGkBzyddw/ppWykfeWHSN6XpjCjl\r\n' +
      'SueYiOefKaWPpCOTa9IitAHCJOUsWXUGHKvqdbwJ1UemCQQhNPRqJIjwcCmc1ZCl\r\n' +
      'BzuWTKNRAoGBAM/I2+r3zCP0RMS/FBrybSEYUmWOcP6qBmvMR+iGYTVxnAcY9Fbk\r\n' +
      'XZV/nI41xzXfkBGbB4Tb14ZbEXFi+FaaUQgAHQlXOcCKaeya0EW1ZjCjQOt3ydI5\r\n' +
      'l/SzeDUSIKxyxVUsjCUADO1fru3EjEdy9GOr7EpqddlO+mkMvvIGZrEdAoGBAKVi\r\n' +
      'OB28P+s/febM9jxJUQp8RyFv41COY2ds334Fve/0IkbI3bobuZl/ATE6cWr8G/qb\r\n' +
      'VZB30GRlTyqvi6I7uxkPnijA5PDsqtoYZWcQtqtm2LQRubofbLwoDmdYO5FXMQd4\r\n' +
      'UlIjfc2ejLQcE8oZ+TeFHSVsRldThyQJrk70B3izAoGAagMD2S0WdV2iXHNU8a6w\r\n' +
      'aQs8tt3m3rem0QcHxSYSoK7XOXPM7ixTZvwVxyS+/8iRe7oKKwnzO3RpQN2K8LyJ\r\n' +
      'DTjpl6NuLsMv6ezOyFIV0kjozXpveRezZMDQWttl2jYwYhV5Z0NQVSfVWzBFlgqb\r\n' +
      'j+LeitUoTXgTINfE8Dy91i0CgYEAhF0L1KCp97olJee825tCRUYaF+TY5cxQOqKk\r\n' +
      'bNRjo/ycwlJZQ26VKQjBB4li5OFJ81UHMkzyeKesqNk+xGVR5UjeqHdZxIlmZI9J\r\n' +
      '+ScjTCNQJS5am2AzQpQ8kvHHGxfRoczm9d55/Wt76Vrfa81F7O2Y2i7CTXoImws0\r\n' +
      'evRMVokCgYEAvaj8++ltKGUtqPKjU3invCyAf4M8GBjFpftGfAKVJu/BdXy2Qq62\r\n' +
      'GU1+DQ76GLdafaq3nTjQVs3KSpyoBEFxxEhrMEEZGHJ+hd0UTdL6AgXzTpINvyUB\r\n' +
      'zg82QS7p/9vnWiksmYh9NzBxkK5R0glsdQhX9laccPxaLaKH556QjwI=\r\n' +
      '-----END RSA PRIVATE KEY-----\r\n'
  },
  opsgenie: {
    authKey: "15a57548-fac3-4385-86d2-7f53413e66eb"
  },
  twilio: {
    long: {
      sid: 'AC7f2eb46aa682550c7d7de49f34ca431f',
      token: '28714e96f6778974deadc8a45b96dcb1',
      from: '+16175440260',
    },
    short: {
      sid: 'AC7f2eb46aa682550c7d7de49f34ca431f',
      token: '28714e96f6778974deadc8a45b96dcb1',
      from: '40508',
    }
  },
  proxy: {
    hosts: {
      'default': '10.129.0.5',
      chop: '192.168.240.66'
    },
    port: 80,
    secret: '3ca4852953be3221a219dadf482715c4'
  },
  dns: {
    zone: 'smplabs',
    credentials: {
      "type": "service_account",
      "project_id": "smp-labs",
      "private_key_id": "c6d009ba28bd07df6f943fd115eec235dc75a6f1",
      "private_key": "-----BEGIN PRIVATE KEY-----\nMIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQCS+3wgBqEUO002\n7zQB+1uLiQ5PXITIqaZ/pRVoGcAQ+Du5nR9/DKgGi5uytlOU3cc+c/fuOHsR/KB0\nwtzk/bp9NFopXAjSU232BEcJltWD5bDWRYxcyThlWkOAovazTkX47yu+5e+n8SIn\n/WC23Q+mqz3v9ozvQ+jss225OTKxnlccK9Gcn4cuFfbfYP9JcT4OydiVjycdLI31\n+6l5nq3KCrSzI+P+4LGRMBVzjV1LJcXToUJRJBtSCx771vtn+OQ5g4m8dHajV7eC\nKO4vdhxEQmTCMrHOPQrJTltPSjl/jQ+pv7yFBgPAHfWu35QKOz4uGmJkAzrY6GTl\nFQFs0af7AgMBAAECggEACJJeBMgeML3cnO2KTOtJxXNywDIXFFTAfwUzoGSQDO1N\nHmAJbDFiwy71H5D+gtoEOqIsvVnR45SmDukmVLxvWcnp0Cur4Ag5i3VoZyjCvvRw\nQa7AptCdV1vSP/hBBufzsLbSCEoElfi8EzT1pPRzazblX88zHOkK9nLoXTr2t+D7\ndzej2cNnIm1rl6nO2+d4wG+yHrrwY+2MhxVKEeHHCVa64JuiZ8P2TrEZIxnR2DGU\nVOohP+2JUfCyOhV/ndyT+QcYEecogS2AanZ1mB+EkkvjcxtCAXDAOIlu7P1vDbzE\n53LoE2MPFHXMGy6aWAZ+6XyCtXyxjhfWz90XuCd9UQKBgQDD1ZT62vwGapMd5L5j\ntHgAHIK3pKJ1ZQA+27sm0HUS+0LP6ZfbdvHLKdDD93NM+YLa4sZzfFhJ3QTHQdsY\n7KW5p7H9X31VykPJXrITzACMrXCrt8vkxEGKCYoCuhVwiTQov1DckY8SQWznnWuc\nkb2M9zLU8GAAi64ML2t+lgfnDwKBgQDAI69qjj9fr8garnONZrwcmKC2JTuFuNjS\nEwfEfcTwM0R2lN2ujJjkWb+Dkq1bmq8Ssbpg2FxHYMuoDQ7tYB4g82elNpH10upL\npVkcH95Fp6rkHAl9SQcL2T4nH24eFU3fzRu3eZ4pqCkuJz2+frseIE3AfbmHi20w\n8WpPLi8QVQKBgQCyzIKlNuL8CLohhQO/jLozf9RpT0t0266JtZz+Jz19AyNRKPmZ\nStQgpiERixAeq4Cp0HtYXaNObRk7xAkCjK4S/+UmbNTGS1HwvSDhqlSmHITOg5UJ\nXZZpLaRwMO36PYr92Q8/EzcJd+qy3auu2/GwjtI+UOmWFfHm9jSy8tBsSQKBgAVC\ndNDT6AQ0iaPBVsRuJYQa4Xta9ZApiTXm83V7g+KXZK5iUNcwXhcSJd3yK/CwAU7f\nspxoD86cZbbJ9Limwuxf/VxWbBRwm51Kf2/3p1v8xP7NjazyTNEUN1FW2OrV5nXT\n5YhWOfAl/EqZJGWMjo66j6ZPSr7dIDYQxbo5J2ypAoGAWyqcjsrfycPDvEGtujao\nVuQpOMofbUG8ip6VIZtjn6IZmoXlfdYNyaNgrwWHFJGKClazC1DuWgNoFMGtY0An\nBxxPgNi9ypBfiksnJREGE2es5I/CDL2XovseQqHHqW/oOcNhGoSOxX1fI2j/GdKZ\nZTHxZSTAYBZuKH71tipJJQ0=\n-----END PRIVATE KEY-----\n",
      "client_email": "dns01-solver@smp-labs.iam.gserviceaccount.com",
      "client_id": "110882826516750597948",
      "auth_uri": "https://accounts.google.com/o/oauth2/auth",
      "token_uri": "https://oauth2.googleapis.com/token",
      "auth_provider_x509_cert_url": "https://www.googleapis.com/oauth2/v1/certs",
      "client_x509_cert_url": "https://www.googleapis.com/robot/v1/metadata/x509/dns01-solver%40smp-labs.iam.gserviceaccount.com"
    }
  },
  fulfilment: {
    email: {
      from: 'smplabs@landislogistics.com',
      to: 'reports@order-fulfillment.smplabs.com',
      subject: 'smp labs end of day report'
    }
  },
  billing: {
    name: 'default',
    skus: {
      GW2: {
        depositCredits: 20,
        annualPrice: 20,
        billable: true
      },
      TAG3: {
        depositCredits: 9,
        annualPrice: 16,
        billable: true
      },
      TAG5: {
        depositCredits: 9,
        annualPrice: 16
      },
      TAG6: {
        depositCredits: 12,
        annualPrice: 19
      },
      TAG7: {
        depositCredits: 9,
        annualPrice: 16
      }
    },
    invoice: {
      billTo: {
        name: 'Commure, Inc.',
        street: '2261 Market St. #4072',
        city: 'San Francisco, CA',
        zip: '94114',
        prefix: 'CO'
      },
      payTo: {
        name: 'Canopy Works, Inc.',
        routing: '322271627',
        account: '932831263',
        contact: 'finance@canopyworks.com'
      },
      data: {
        folderId: '1ATRF9bOLMji45ownatvijo0wtoJ5Q60B'
      }
    }
  },
  tagSecurity: {
    auth: {
      key: Buffer.from('412e1835d89f7e787380a4e8e123f404', 'hex'),
      salt: Buffer.from('4eed30aeda11fc19', 'hex'),
      blockSize: 16,
      truncatedLength: {
        alert: 8,
        admin: 4,
        outdoorAlert: 4
      }
    },
    encrypt: {
      key: Buffer.from('27a5f4ab6d4c76b066aa275669bda20c', 'hex'),
      salt: Buffer.from('39680c34d32de86c', 'hex'),
      blockSize: 16,
      authTagLen: 4,
      ivEntropyThreshold: 3.0
    }
  },
  chirpstack: {
    production: {
      apiKey: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOiJjaGlycHN0YWNrIiwiaXNzIjoiY2hpcnBzdGFjayIsInN1YiI6IjllZWZhMzExLTFkYTMtNGFlOC04MzAwLTg3ZmQ5NGEyZWZhMSIsInR5cCI6ImtleSJ9.h-LjOhnRPkc3ddP0zItMWAhD_rZf36XJvyg3H4Gi-8I',
      provKey: 'aabbccddeeff' // Stopgap prov key. Not intended to be cryptographically secure.
    },
    staging: {
      apiKey: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOiJjaGlycHN0YWNrIiwiaXNzIjoiY2hpcnBzdGFjayIsInN1YiI6ImU5OWIyY2QyLTk3Y2YtNGYwOC04OWY1LTY2MTk2Mzc1NTc4NSIsInR5cCI6ImtleSJ9.2IO-sQ4YR6XN37asLHJnI-Hnwayx1jsUk20yqye14rg',
      provKey: 'aabbccddeeff' // Stopgap prov key. Not intended to be cryptographically secure.
    },
    ids: {
      app: 'b98dc4e5-ae55-4788-81b0-7ce4ad64370b',
      defaultUSDeviceProfile: '505582ad-1b3e-49c6-842d-43168ecfc58f'
    }
  }
}
