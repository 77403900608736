import api from '@/lib/api'
import { consoleConfig } from '@/lib/console'
import dangers from '../../../../processor/src/lib/dangers'

// initial state
const state = {
  alerts: [],
  monitoredZones: null,
  acknowledged: {},
  reportedErrors: {},
  loading: false,
  lastRefreshTimestamp: Date.now()
}
const alertRefreshTimeoutMs = 10000 // 10 seconds

// getters
const getters = {
  alerts: state => state.alerts.filter(alert => alert && alert.mode !== 'danger'),
  monitoredZones: state => state.monitoredZones,
  acknowledged: state => state.acknowledged,
  reportedErrors: state => state.reportedErrors,
  loading: state => state.loading,
  reasons: state => ({
    resolved: [
      { code: 'resolved_handled', text: 'Duress incident / handled by security' },
      { code: 'resolved_before', text: 'Duress incident / resolved before arrival' },
      { code: 'resolved_duplicate', text: 'Duplicate event' },
      { code: 'resolved_nonduress', text: 'Non-duress situation' },
      { code: 'resolved_test', text: 'Intentional test' },
      { code: 'resolved_accidental', text: 'Accidental button press' },
      { code: 'resolved_other' }
    ],
    ignored: [
      { code: 'ignored_duplicate', text: 'Duplicate alert on same unit' },
      { code: 'ignored_test', text: 'Intentional test' },
      { code: 'ignored_other' },
      { code: 'test', text: 'Walkthrough Test' }
    ],
    danger: dangers.list
  }),
  dangerousLocations: state => state.alerts.filter(alert => alert.mode === 'danger' && ['created', 'notified'].includes(alert.state))
}

// actions
const actions = {
  refresh: async ({ commit, state, rootGetters }, { skipCache } = {}) => {
    commit('SET_LOADING', true)
    try {
      const includeTests = rootGetters['auth/can']('read', 'Alert', 'test')
      const { zones } = rootGetters.consoleConfig || (await consoleConfig())
      commit('SET_MONITORED_ZONES', zones)
      let alertQuery = `alerts/?test=${includeTests}`
      if (skipCache) {
        alertQuery += '&skipCache=true'
      }
      if (zones && zones.length) {
        const zoneIds = zones.map(zone => zone.id)
        alertQuery = alertQuery.concat(`&zones=${String(zoneIds)}`)
      }
      const { data: alerts } = await api.get(alertQuery)
      commit('SET_ALERTS', alerts)
      commit('SET_REFRESH_TIMESTAMP', Date.now())
      if (!rootGetters.connected) {
        commit('SET_CONNECTED', true, { root: true })
      }
    } catch (error) {
      console.log(error)
      if (Date.now() - state.lastRefreshTimestamp > alertRefreshTimeoutMs) {
        commit('SET_CONNECTED', false, { root: true })
      }
    }
    commit('SET_LOADING', false)
  },
  refreshAlert: async ({ commit }, { id }) => {
    commit('SET_LOADING', true)
    try {
      const { data: alert } = await api.get(`alerts/${id}`)
      if (alert && alert.id) {
        commit('SET_ALERT', alert)
      }
    } catch (error) {
      console.log(error)
    }
    commit('SET_LOADING', false)
  },
  acknowledge: async ({ commit, state }, { alert, time = 30000 }) => {
    const id = alert.id
    if (state.acknowledged[id]) return
    setTimeout(() => commit('RESET_ACK', id), time)
    commit('SET_ACK', id)
    try {
      await api.put(`alerts/${id}`, { acknowledged: true })
    } catch (error) {
      console.log(error)
    }
  },
  reportError: async ({ commit, state }, alert) => {
    const id = alert.id
    if (state.reportedErrors[id] || alert.mode !== 'test') return
    setTimeout(() => commit('RESET_ERROR', id), 10000)
    commit('SET_ERROR', id)
    try {
      await api.post(`log/alerts/error/${id}`, {
        tag: alert.tag.id || alert.tag,
        clientLastRefreshDelta: Date.now() - state.lastRefreshTimestamp
      })
    } catch (error) {
      console.log(error)
    }
  }
}

// mutations
const mutations = {
  SET_ALERTS (state, alerts) {
    state.alerts = alerts
  },
  SET_ALERT (state, alert) {
    const index = state.alerts.findIndex(a => a.id === alert.id)
    if (index >= 0) {
      state.alerts.splice(index, 1, alert)
    } else {
      state.alerts.push(alert)
    }
  },
  SET_ACK (state, id) {
    state.acknowledged = { ...state.acknowledged, [id]: true }
  },
  SET_MONITORED_ZONES (state, zones) {
    state.monitoredZones = zones
  },
  SET_ERROR (state, id) {
    state.reportedErrors = { ...state.reportedErrors, [id]: true }
  },
  RESET_ACK (state, id) {
    const acknowledged = { ...state.acknowledged }
    delete acknowledged[id]
    state.acknowledged = acknowledged
  },
  RESET_ERROR (state, id) {
    const reportedErrors = { ...state.reportedErrors }
    delete reportedErrors[id]
    state.reportedErrors = reportedErrors
  },
  SET_LOADING (state, loading) {
    state.loading = loading
  },
  SET_REFRESH_TIMESTAMP (state, timestamp) {
    state.lastRefreshTimestamp = timestamp
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
