<template>
  <div>
    <tree-select
      :ref="`tree-${id}`"
      :options="options"
      :load-options="loadOptions"
      :auto-load-root-options="!lazyLoadRoot"
      :openDirection="down"
      :before-clear-all="onClear"
      :value="multiple ? uniq(value) : value"
      :close-on-select="!opened"
      :disable-branch-nodes="!!noBranches"
      :multiple="multiple"
      :placeholder="placeholder || user.zone.name"
      :limit="limit || 3"
      :limitText="limitText"
      @input="onInput"
      @select="onChange"
      @deselect="onChange"
      @close="onClose"
    >
      <div slot="value-label" slot-scope="{ node }">{{ valueLabel(node) }}</div>
      <label v-if="node.editing" slot="option-label" slot-scope="{ node }"
        class="my-auto py-1" @mousedown.stop>
        <input :ref="`input-${node.id}`"
          class="py-0 pl-1"
          type="text"
          placeholder="Enter name..."
          v-model="addNodeName"
          @keydown.enter.prevent="onSave"
          @keydown.escape.stop="onEscape(node)"
          @blur.stop="onEscape(node)"
          />
      </label>
      <label v-else slot="option-label" slot-scope="{ node }" class="addable my-auto">
        {{ node.label }}
        <d-link v-if="editable && level(node) !== 'area'"
          class="action add ml-2" @mousedown.native.stop="onAdd(node)">add</d-link>
        <d-link v-if="editable && node.isLeaf" class="action del ml-2" @mousedown.native.stop="onDelete(node)">del</d-link>
      </label>
    </tree-select>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { LOAD_ROOT_OPTIONS, LOAD_CHILDREN_OPTIONS } from '@riophae/vue-treeselect'
import utils from '@/mixins/utils'
import _ from 'lodash'

export default {
  name: 'zone-select',
  props: [
    'peered', //
    'id',
    'value',
    'opened',
    'isVisible',
    'noBranches',
    'isLeaf',
    'limit',
    'limitText',
    'editable',
    'multiple',
    'from',
    'showRoot',
    'placeholder',
    'maxLevel',
    'lazyLoadRoot'
  ],
  mixins: [utils],
  data () {
    return {
      ready: false,
      options: null,
      addedNode: null,
      showAddNode: null,
      addNodeName: null
    }
  },
  computed: {
    ...mapGetters('auth', [
      'user'
    ]),
    ...mapGetters('zones', [
      'zone',
      'subzones',
      'pathTo'
    ]),
    roots () {
      const roots = []

      let authorizedZones
      const peers = _.get(this.user.zone, 'peers')
      if (peers && Array.isArray(peers) && peers.length && peers.every(item => typeof item === 'string')) {
        authorizedZones = [this.user.zone.id, ...peers]
      }
      const root = typeof this.from === 'function' ? this.from() : (this.from || authorizedZones || this.user.zone.id)
      if (root) {
        if (Array.isArray(root)) {
          roots.push(...root)
        } else {
          roots.push(root)
        }
      }

      return roots
    },
    root () {
      return typeof this.from === 'function' ? this.from() : (this.from || this.user.zone.id)
    }
  },
  methods: {
    uniq (list) {
      return _.uniq(list)
    },
    ...mapActions('zones', [
      'loadPath',
      'add',
      'delete'
    ]),

    /**
     * [loadPartial description]
     * @param  string | list[string], value ~ the zoneId(s) to load and splice into the options
     * @return {[type]}       [description]
     */
    async loadPartial (value) {
      value = value || this.value || this.roots
      if (value) {
        if (Array.isArray(value)) {
          for (const zone of value) {
            await this.loadPartial(zone)
          }
          return
        }
        try {
          await this.loadPath({ zone: value })
        } catch (err) {
          return
        }
      }

      let id = value
      let options = null
      while (id) {
        const parent = this.findInOptions(this.options, id)
        if (parent) {
          if (options) parent.children = options
          break
        }

        const zone = this.zone(id)
        if (!zone || !zone.parent) break

        // getting subzones for id
        let subzones = []
        if (this.roots.includes(id)) {
          subzones.push(zone)
        } else {
          subzones.push(...this.subzones(zone.parent))
        }

        if (this.isVisible) {
          subzones = subzones.filter(this.isVisible)
        }

        options = [...subzones].sort(this.zoneSort).map(zone => {
          const isLeaf = zone.isLeaf || (this.isLeaf && this.isLeaf(zone)) || zone.level === this.maxLevel
          let children = isLeaf ? {} : { children: null }
          if (zone.id === id && options && !isLeaf) {
            children = { children: options }
          }
          return {
            id: zone.id,
            label: zone.name,
            level: zone.level,
            isDefaultExpanded: zone.id === id && id !== value && !isLeaf,
            ...children
          }
        })

        if (Array.isArray(this.from) && this.from.includes(id)) break
        if (this.roots.includes(id)) break

        id = zone.parent
      }

      if (this.opened) {
        process.nextTick(() => this.$refs[`tree-${this.id}`].openMenu())
      }
    },
    findInOptions (options, id) {
      if (!options || !options.length) return
      for (const item of options) {
        if (item.id === id) return item
        const match = this.findInOptions(item.children, id)
        if (match) return match
      }
    },

    async loadRootOptions ({ action, parentNode }) {
      if (!this.roots.length) {
        this.options = null
        return
      }

      /*
       * load the "root" zoneId(s) + create a list of their immediate children
       */
      let subzones
      let from = typeof this.from === 'function' ? this.from() : this.from
      // console.log('from =', from, 'this.roots =', this.roots)

      if (from && typeof from === 'string') {
        from = [from]
      }

      if (Array.isArray(from)) {
        for (const zone of from) {
          try {
            await this.loadPath({ zone })
          } catch (error) {
            // handle 404s, otherwise rethrow
            if (_.get(error, 'response.status') !== 404) {
              throw error
            }
          }
        }
        if (!subzones) subzones = []
        subzones.push(...from.map(zone => this.zone(zone)).filter(x => x))
      } else if (this.roots.length > 1) {
        //
        for (const zone of this.roots) {
          await this.loadPath({ zone }) // best case, this is a dictionary check
          const x = this.zone(zone)
          if (x) {
            if (!subzones) subzones = []
            subzones.push(x)
          }
        }
      } else {
        //
        for (const zone of this.roots) {
          await this.loadPath({ zone }) // best case, this is a dictionary check
          const x = this.subzones(zone) || []
          if (x.length) {
            if (!subzones) subzones = []
            subzones.push(...x)
          }
        }
      }

      // TODO
      // ~ this ... confuses me ...
      // ~ this may be unnecessary with the loadPath change above ...
      // - [ ] DRY
      if (!subzones) {
        for (const zone of this.roots) {
          await this.loadPath({ zone }) // best case, this is a dictionary check
          const x = this.subzones(zone) || []
          if (x.length) {
            if (!subzones) subzones = []
            subzones.push(...x)
          }
        }
      }

      if (this.isVisible) {
        subzones = subzones.filter(this.isVisible)
      }

      this.options = [...subzones].filter(x => x).sort(this.zoneSort).map(zone => {
        const isLeaf = zone.isLeaf || (this.isLeaf && this.isLeaf(zone)) || zone.level === this.maxLevel
        const children = isLeaf ? {} : { children: null }
        return {
          id: zone.id,
          label: zone.name,
          level: zone.level,
          ...(isLeaf && { isLeaf }),
          isDefaultExpanded: zone.id === from && !isLeaf, // TODO ~ what if from is an array?
          ...children
        }
      })

      await this.loadPartial() // TODO ~ be explicit
      this.ready = true
    },

    async loadOptions ({ action, parentNode }) {
      if (action === LOAD_ROOT_OPTIONS) {
        return await this.loadRootOptions({ action, parentNode })
      } else if (action === LOAD_CHILDREN_OPTIONS) {
        // TODO ~ re-org into method
        let subzones = this.subzones(parentNode.id)
        if (!subzones) {
          await this.loadPath({ zone: parentNode.id })
          subzones = this.subzones(parentNode.id)
        }
        if (this.isVisible) {
          subzones = subzones.filter(this.isVisible)
        }
        if (subzones.length) {
          parentNode.children = [...subzones].sort(this.zoneSort).map(zone => {
            const isLeaf = zone.isLeaf || (this.isLeaf && this.isLeaf(zone)) || zone.level === this.maxLevel
            const children = isLeaf ? {} : { children: null }
            return {
              id: zone.id,
              label: zone.name,
              level: zone.level,
              ...children
            }
          })
        } else {
          delete parentNode.children
        }
      }
    },

    deleteNode () {
      const node = this.addedNode
      if (!node) return
      const children = (node.parentNode || {}).children
      if (children) {
        const index = children.findIndex(n => n.id === node.id)
        children.splice(index, 1)
      }
      this.addedNode = null
    },
    isAdded (node) {
      return this.addedNode && this.addedNode.id === node.id
    },
    isEditing () {
      return this.addedNode && this.addedNode.editing
    },
    onClear () {
      this.$emit('change')
      return true
    },
    onInput (value) {
      this.$emit('input', value)
    },
    onChange (node) {
      this.$emit('change', node.id)
    },
    onClose () {
      if (this.isEditing()) {
        this.$refs[`tree-${this.id}`].openMenu()
      } else {
        // this.$refs[`tree-${this.id}`].initialize()
        this.$emit('close')
      }
      if (typeof this.from === 'function') {
        this.options = null
      }
    },
    onAdd (node) {
      if (this.addedNode) {
        this.deleteNode()
      }
      const id = `new-${Math.floor(Math.random() * 1000000)}`
      this.addNodeName = undefined
      this.addedNode = {
        id,
        label: '',
        level: node.level + 1,
        isLeaf: true,
        editing: true,
        parentNode: node,
        isExpanded: false
      }
      node.children.unshift(this.addedNode)
      process.nextTick(() => this.$refs[`input-${id}`].focus())
    },
    onDelete (node) {
      this.delete(node.id)
        .then(() => {
          if (this.value === node.id) {
            this.value = node.parentNode.id
          }
          this.options = null
          this.loadPartial()
        })
        .catch(({ response }) => {
          if (response && response.status === 403) {
            alert(`Could not delete zone "${node.label}" since it's assigned to a gateway`)
          } else {
            alert(`Sorry, there was a problem deleting zone "${node.label}"`)
          }
        })
    },
    async onSave () {
      const node = this.addedNode
      if (!node) return
      const name = this.addNodeName.trim()
      const zone = {
        name,
        parent: node.parentNode.id
      }
      this.add(zone)
        .then(async ({ id }) => {
          this.deleteNode()
          this.options = null
          await this.loadOptions({ action: LOAD_ROOT_OPTIONS })
          await this.loadPartial(id)
          this.value = id
          this.$emit('change', id)
        })
        .catch((error) => {
          alert(`Sorry, there was a problem adding zone "${name}": ${error}`)
        })
    },
    onEscape (blur) {
      this.deleteNode()
      this.addNodeName = undefined
    },
    valueLabel (node) {
      if (this.ready) {
        if (!node) return
        if (this.isAdded(node)) {
          return this.addedNode.label
        }
        if (this.roots.includes(node.id) && !this.showRoot) {
          // console.log('this.id = ', this.id)
          this.$refs[`tree-${this.id}`].clear()
          return
        }
        if (node.id === this.root && !this.showRoot) {
          this.$refs[`tree-${this.id}`].clear()
          return
        }
        return node.label
      }

      return 'Loading...'
    },
    level (node) {
      const zone = this.zone(node.id)
      if (!zone) return
      return zone.level
    },
    clearAll () {
      if (this.multiple) {
        this.value = []
        this.$emit('input', [])
        this.$emit('change', [])
      } else {
        this.value = null
        this.$emit('input')
        this.$emit('change')
      }
    }
  }
}
</script>

<style scoped>
  .action {
    font-size: 8pt;
    visibility: hidden;
  }
  .action.add {
    /* color: #17c671; */
    color: #3d5170;
  }
  .action.del {
    /* color: #c4183c; */
    color: #3d5170;
  }
  .addable:hover .action {
    visibility: visible !important;
  }
</style>

<style>
  .vue-treeselect__indent-level-0 .vue-treeselect__option {
    margin-left: 5px;
  }
  .vue-treeselect__indent-level-1 .vue-treeselect__option {
    margin-left: 15px;
  }
  .vue-treeselect__indent-level-2 .vue-treeselect__option {
    margin-left: 25px;
  }
  .vue-treeselect__indent-level-3 .vue-treeselect__option {
    margin-left: 35px;
  }
  .vue-treeselect__indent-level-4 .vue-treeselect__option {
    margin-left: 45px;
  }
  .vue-treeselect__indent-level-5 .vue-treeselect__option {
    margin-left: 55px;
  }
  .vue-treeselect__indent-level-6 .vue-treeselect__option {
    margin-left: 65px;
  }
  .vue-treeselect__control {
    white-space: nowrap;
    overflow: scroll;
  }
  .vue-treeselect__label-container {
    font-size: 10pt;
  }
  .vue-treeselect__label-container input {
  }
  .vue-treeselect__value-container {
    font-size: 10pt;
    font-weight: normal;
  }
  .vue-treeselect__menu {
    white-space: nowrap;
    overflow: scroll;
    width: 300px;
  }
  .table-responsive {
    overflow-x: visible;
  }
  .VueTables__table.dataTable {
    overflow: visible !important;
  }
</style>
