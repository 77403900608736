/* eslint-disable */
import Vue from 'vue'
import ShardsVue from 'shards-vue'
import { abilitiesPlugin } from '@casl/vue'
import { ClientTable, ServerTable } from 'vue-tables-2'
import VMdDateRangePicker from 'v-md-date-range-picker'
import TreeSelect from '@riophae/vue-treeselect'
import VuePlaceAutocomplete from '@hemanthm/vue-place-autocomplete'
import VueJsonPretty from 'vue-json-pretty'
import Multiselect from 'vue-multiselect'
import { detectDomain } from '@/lib/util'
import { LicenseManager } from 'ag-grid-enterprise'

// Styles
import 'bootstrap/dist/css/bootstrap.css'
import '@/assets/shards-dashboard-pro/shards-dashboards.scss'
import 'leaflet/dist/leaflet.css'
import 'leaflet-toolbar/dist/leaflet.toolbar.css'
import 'leaflet-draw/dist/leaflet.draw.css'
import 'leaflet-fullscreen/dist/leaflet.fullscreen.css'
import 'leaflet-distortableimage/dist/leaflet.distortableimage.css'
import 'leaflet.awesome-markers/dist/leaflet.awesome-markers.css'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import 'vue-json-pretty/lib/styles.css'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import browserDetect from 'vue-browser-detect-plugin'

// Core
import App from '@/App.vue'
import auth from '@/lib/auth'
import router from '@/router'
import store from '@/store'
import { domains } from '../../processor/src/config'
import config from './config'
import { consoleId } from '@/lib/console'

// Layouts/Components
import DefaultLayout from '@/layouts/Default'
import NoFooterLayout from '@/layouts/NoFooter'
import EmptyLayout from '@/layouts/Empty'
import KioskLayout from '@/layouts/Kiosk'
import LogoLayout from '@/layouts/Logo'
import LoadingLayout from '@/layouts/Loading'
import DatePicker from '@/components/DatePicker'
import ItemList from '@/components/ItemList'
import FloorPlan from '@/components/FloorPlan'
import ZoneList from '@/components/ZoneList'
import ZoneSelect from '@/components/ZoneSelect'
import ZonePicker from '@/components/ZonePicker'
import LoadingOverlay from '@/components/LoadingOverlay'
import Suggest from '@/components/Suggest'
import SuggestMulti from '@/components/SuggestMulti'
import Toggle from '@/components/Toggle'
import Generator from '@/components/Generator'
import Navigator from '@/plugins/navigator'
import AlertReasonEditor from'@/components/AlertReasonEditor'
import RadioList from '@/components/RadioList'
import './registerServiceWorker'

Vue.prototype.$domain = detectDomain() || 'strongline'
Vue.prototype.$domains = domains
Vue.prototype.$brand = domains[Vue.prototype.$domain].branding

Vue.use(abilitiesPlugin, store.getters['auth/ability'])
Vue.use(ShardsVue)
Vue.use(ClientTable)
Vue.use(ServerTable)
Vue.use(VMdDateRangePicker)
Vue.use(VuePlaceAutocomplete)
Vue.use(Navigator, router, 'distribution')
Vue.use(browserDetect)

Vue.component('default-layout', DefaultLayout)
Vue.component('nofooter-layout', NoFooterLayout)
Vue.component('empty-layout', EmptyLayout)
Vue.component('kiosk-layout', KioskLayout)
Vue.component('logo-layout', LogoLayout)
Vue.component('loading-layout', LoadingLayout)
Vue.component('date-picker', DatePicker)
Vue.component('item-list', ItemList)
Vue.component('floor-plan', FloorPlan)
Vue.component('zone-list', ZoneList)
Vue.component('zone-select', ZoneSelect)
Vue.component('zone-picker', ZonePicker)
Vue.component('loading-overlay', LoadingOverlay)
Vue.component('suggest', Suggest)
Vue.component('suggest-multi', SuggestMulti)
Vue.component('toggle', Toggle)
Vue.component('phrase-generator', Generator)
Vue.component('tree-select', TreeSelect)
Vue.component('alert-reason-editor', AlertReasonEditor)
Vue.component('radio-list', RadioList)
Vue.component('vue-json-pretty', VueJsonPretty)
Vue.component('multiselect', Multiselect)

Vue.config.productionTip = false
Vue.config.warnHandler = function (msg, vm, trace) {
  if (msg.startsWith('The .native modifier for v-on is only valid on components but it was used on')) {
    msg = null;
    vm = null;
    trace = null;
  }
}

// set AG Grid Enterprise license key
LicenseManager.setLicenseKey(config.agGridLicenseKey)

consoleId()
store.dispatch('pwa/setup')

auth.initialize()

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
